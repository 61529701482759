import ScreenModal from '@components/Modals/ScreenModal';
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { removeFileVersionsFromStore, removeFileFromVersions, deleteFile } from '@redux/files/index.actions';
import { toggleFilesVersionModal } from '@redux/components/index.actions';
import './index.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DefaultLoader } from '@components/Loader';
import AcceptOrClosePopup from '@popups/AcceptOrClosePopup';
import cdnServicesInstance from '@clients/cdnservices.client';
import customCDNServicesInstance from '@clients/customcdn.client';

const mapStateToProps: any = (state: any) => ({
    components: state.components,
    files: state?.files,
    users: state?.users
})

const mapActionsToProps: any = {
    toggleFilesVersionModal,
    removeFileVersionsFromStore,
    removeFileFromVersions,
    deleteFile
}

const FilesVersionsPopup: React.FC<any> = (props) => {
    const { t } = useTranslation();
    const [showAcceptOrCloseModal, setShowAcceptOrCloseModal] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean[]>([]);
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState<number | null>(null);
    const openDeleteModal = (file: any, index: number) => {
        if (!showAcceptOrCloseModal) {
            setCurrentDeleteIndex(index);
            setShowAcceptOrCloseModal(true);
        }
    }

    const generateFileUrl = (file: any) => {
        if (file?.source?.sourceKey === "amazon-s3") {
            return cdnServicesInstance.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "custom-cdn") {
            return file?.fileUrl + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "google-drive") {
            return file?.fileUrl;
        } else if (file?.source?.sourceKey === "one-drive") {
            return file?.fileUrl;
        } else if (file?.source?.sourceKey === "share-point") {
            //return file?.fileUrl;
            //return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
            return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file.key}/url` + "?accessKey=" + props?.users?.authorization?.token;
        } else {
            return ""
        }
    }

    const generateDownloadUrl = (file: any) => {
        if (file?.source?.sourceKey === "amazon-s3") {
            return cdnServicesInstance.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "custom-cdn") {
            return props?.companies?.company?.customCDNEndpoint + "/local/download/" + file?.key + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "google-drive") {
            return cdnServicesInstance.defaults.baseURL + `/gdrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "one-drive") {
            return cdnServicesInstance.defaults.baseURL + `/onedrive/files/download/${file?.key}` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "share-point") {
            return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else {
            return ""
        }
    }

    useEffect(() => {
        return () => {
            if (!props.components?.showFilesVersions) {
                props?.removeFileVersionsFromStore(true)
            }
        }
    }, [])
    return (
        <div>
            <ScreenModal
                renderHeader={(<h4>File Versions</h4>)}
                size="xl"
                show={props.components?.showFilesVersions && !showAcceptOrCloseModal}
                onClose={() => {
                    props?.toggleFilesVersionModal(false);
                }}>
                <React.Fragment>
                    <div style={{ width: "100%", minHeight: "500px" }}>
                        <table className="table table-stripped file-version-table" style={{
                            position: "relative",
                            minWidth: "100%",
                            overflowY: "visible",
                            overflowX: "scroll",
                            minHeight: "50px"
                        }}>
                            <thead>
                                <tr>
                                    <th>{t("FILES.VERSIONS_POPUP.FILENAME")}</th>
                                    <th>{t("FILES.VERSIONS_POPUP.VERSION")}</th>
                                    <th>{t("FILES.VERSIONS_POPUP.CREATED_DATE")}</th>
                                    <th>{t("FILES.VERSIONS_POPUP.UPLOADED_BY")}</th>
                                    <th>{t("FILES.VERSIONS_POPUP.ACTIONS")}</th>
                                </tr>
                            </thead>
                            <tbody style={{ height: "100%" }}>

                                {props?.files?.fetchedVersions && (props?.files?.versions || []).map((item: any, index: number) => (<tr>
                                    <td className="filename">
                                        <a target="__blank" href={generateFileUrl(item)}>{item?.filename}</a>
                                    </td>
                                    <td className="version">
                                        v{item?.currentVersion}
                                    </td>
                                    <td className="createdDate">
                                        {moment(new Date(item?.createdAt)).format('LLLL')}
                                    </td>
                                    <td className="uploadedBy">
                                        {item?.user?.name + " " + item?.user?.surname}
                                    </td>
                                    {index !== 0 ? <td className="actions">
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                as="a"
                                                style={{
                                                    border: "1px solid black"
                                                }}
                                                className="cursor-pointer table-action-btn btn btn-light btn-xs">
                                                <i className="uil uil-ellipsis-h"
                                                    style={{
                                                        fontSize: "0.8rem"
                                                    }}></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu align="end">
                                                <Dropdown.Item onClick={async () => {
                                                    await props?.removeFileFromVersions({ _id: item?._id })
                                                    if ((props?.files?.versions || []).length <= 1) {
                                                        props?.toggleFilesVersionModal(false)
                                                    }
                                                }}>
                                                    <i className="uil uil-trash me-2 text-muted vertical-middle"></i>
                                                    {t("FILES.VERSIONS_POPUP.REMOVE_FROM_VERSIONS")}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={(e: any) => {
                                                    window.open(generateDownloadUrl(item), "__blank")
                                                }}>
                                                    <i className="uil uil-arrow-to-bottom me-2 text-muted vertical-middle"></i>
                                                    {t("FILES.VERSIONS_POPUP.DOWNLOAD")}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={async (e: any) => {
                                                    openDeleteModal(item, index);
                                                }}>
                                                    <i className="uil uil-trash-alt me-2 text-muted vertical-middle"></i>
                                                    {t("FILES.VERSIONS_POPUP.DELETE")}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td> : <td className="actions">-</td>}
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        {!props?.files?.fetchedVersions && <div style={{
                            width: "100%",
                            height: "300px",
                            display: "flex",
                            marginBottom: "100px",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <div>
                                <DefaultLoader />
                            </div>
                        </div>
                        }
                    </div>
                </React.Fragment >
            </ScreenModal >
            <AcceptOrClosePopup
                show={showAcceptOrCloseModal}
                onAccept={async () => {
                    if (currentDeleteIndex !== null) {
                        await props?.deleteFile({
                            _id: props?.files?.versions[currentDeleteIndex]?._id
                        })
                        if ((props?.files?.versions || []).length <= 1) {
                            props?.toggleFilesVersionModal(false)
                        }
                    }
                }}
                renderHeader={(<h4>{t("FILES.DELETE_FILE")}</h4>)}
                onClose={() => {
                    setShowAcceptOrCloseModal(false);
                    setCurrentDeleteIndex(null)
                }}
            >
                <div>{t("FILES.DELETE_FILE_DESCRIPTION")}</div>
            </AcceptOrClosePopup>
        </div >
    )
}

export default connect(mapStateToProps, mapActionsToProps)(FilesVersionsPopup);