/* eslint-disable */
export default {
    LANGUAGE_SHORT_CODE: 'en',
    CREATE_BUTTON: 'Create',
    LEFT_MENU: {
        REPORTS: "Reports",
        PIPELINE: "Pipeline",
        FILES: "Files",
        CONTRACTS: "Contracts",
        ASSETS: "Assets",
        STATUSES: "Statuses",
        PIPELINES: "Pipelines",
        CUSTOM_FIELDS: "Custom Fields",
        CUSTOM_FORMS: "Custom Forms",
        CONTRACT_TYPES: "Contract Types",
        CONTRACT_COMPANIES: "Contract Companies",
        CONTRACT_CONTACTS: "Contract Contacts",
        MY_ACCOUNT: "My Account",
        INBOX: "Inbox",
        TEMPLATES: "Templates"
    },
    API_TABLE: {
        SEARCH_FOR_KEYWORDS: "Search For Keywords",
        SEARCH: "Search",
        CLEAR: "Clear",
        DISPLAY: "Display:",
        ENTRIES: "Entries",
        TOTAL: "Total:",
        NO_DATA_FOUND: "No data found in the system"
    },
    API_TABLE_CUSTOM_COMPONENT: {
        SEARCH_FOR_KEYWORDS: "Search For Keywords",
        SEARCH: "Search",
        CLEAR: "Clear",
        EXPORT: "Export",
        EXPORT_TO_EXCEL: "Export to Excel",
        PLEASE_WAIT: "Please wait...",
        DISPLAY: "Display:",
        ENTRIES: "Entries",
        TOTAL: "Total:",
        NO_DATA_FOUND: "No data found in the system"
    },
    ACTIONS_DROPDOWN_MENU: {
        GENERAL: "General",
        CONTRACT: "Contract",
        FILE: "File",
        USER: "User",
        ASSET: "Asset",
        PIPELINE: "Pipeline",
        STATUS: "Status",
        CONTRACT_TYPE: "Contract Type",
        CUSTOM_FIELD: "Custom Field",
        TYPE: "Contract Types",
        COMPANY: "Company",
        CONTACT: "Contact"
    },
    PROFILE_DROPDOWN_MENU: {
        WELCOME: "Welcome !",
        PROFILE: "Profile",
        MY_ACCOUNT: "My Account",
        MY_INTEGRATIONS: "My Integrations",
        CREATE_COMPANY: "Create Company",
        COMPANY_SWITCH: "Company Switch",
        COMPANY: "Company",
        COMPANY_SETTINGS: "Company Settings",
        COMPANY_USERS: "Company Users",
        COMPANY_INVITES: "Company Invites",
        COMPANY_ROLES: "Company Roles",
        COMPANY_INTEGRATIONS: "Company Integrations",
        COMPANY_PIPELINES: "Company Pipelines",
        COMPANY_STATUSES: "Company Statuses",
        LOG_OUT: "Log Out",
        LOCK_SCREEN: "Lock Screen",
        LOGOUT: "Logout"
    },
    LANGUAGE: {
        ENGLISH: "English",
        TURKISH: "Turkish"
    },
    APPS: {
        DRIVE_IMPORT: "Drive Import",
        DRIVE_EXPORT: "Drive Export",
        GMAIL_IMPORT: "Gmail Import",
        ONEDRIVE_IMPORT: "OneDrive Import",
        ONEDRIVE_EXPORT: "OneDrive Export",
        SHAREPOINT_IMPORT: "SharePoint Import",
        SHAREPOINT_EXPORT: "SharePoint Export",
    },
    COMPANIES: {
        DETAILS: {
            TITLE: "COMPANY SETTINGS",
            DESCRIPTION: "Details of the current company: you can read, edit, delete details in the company",
            COMPANY_TITLE_LABEL: "Company Title:",
            COMPANY_TITLE_PLACEHOLDER: "Company Title",
            COMPANY_DESCRIPTION_LABEL: "Company Description:",
            COMPANY_DESCRIPTION_PLACEHOLDER: "Company Description",
            COMPANY_DOMAIN_LABEL: "Company Domain:",
            COMPANY_DOMAIN_PLACEHOLDER: "Company Domain",
            COMPANY_LOGO_URL_LABEL: "Logo Url (300x300):",
            COMPANY_LOGO_URL_PLACEHOLDER: "https://website.com/logo.png",
            SAVE_CHANGES: "Save Changes",
            DELETE_COMPANY_LABEL: "DELETE YOUR COMPANY",
            DELETE_COMPANY_DESCRIPTION: "You can delete your company from Jetlexa. Your company information will be stored for 30 days after delete command. You can reach us after deletion via support channel to restore your information.",
            DELETE_COMPANY_CHECK_DESCRIPTION: "You can type 'permanent-delete' into input box to delete your company",
            DELETE_CHECK_LABEL: "Delete Check:",
            DELETE_CHECK_PLACEHOLDER: "permanent-delete",
            DELETE_CHECK_INVALID: "Please provide 'permanent-delete' key into the input box",
            DELETE_COMPANY_BUTTON: "Delete Company",
            CDN_ENDPOINT_TITLE: "Custom CDN Endpoint",
            CDN_ENDPOINT_DESCRIPTION: "You can use your own CDN endpoint to store your companies files. If you provide any CDN endpoint, we will use our CDN endpoint to store your companies files.",
            CDN_ENDPOINT: "CDN Endpoint",
            CDN_ENDPOINT_PLACEHOLDER: "https://cdn-jetlexa.example.com",
            CDN_ENDPOINT_LOCKED: "You can not change CDN Endpoint after you set it. If you want to change CDN Endpoint, please contact us via support channel.",
            SAVE: "Save Changes"
        },
        USERS: {
            TITLE: "COMPANY USERS",
            DESCRIPTION: "Users of the current company: you can search, read, edit, delete for users in the company",
            INVITE_USER: "Invite a new person",
            TABLE: {
                ID: "ID",
                NAME: "Name",
                SURNAME: "Surname",
                EMAIL: "Email",
                PHONE_NUMBER: "Phone Number",
                USERNAME: "Username",
                CREATED_DATE: "Created Date"
            }
        },
        INVITES: {
            TITLE: "COMPANY INVITES",
            DESCRIPTION: "Invites of the current company: you can search, read, edit, delete for users invited in the company",
            INVITE_USER: "Invite a new person",
            TABLE: {
                ID: "ID",
                EMAIL: "Email",
                STATUS: "Status",
                CREATED_DATE: "Created Date"
            }
        },
        ROLES: {
            TITLE: "COMPANY ROLES",
            DESCRIPTION: "Roles of the current company: you can search, read, edit, delete for roles in the company",
            CREATE_ROLE: "Create a role",
            TABLE: {
                ID: "ID",
                TITLE: "Title",
                NOMINATIONS: "Nominations",
                CREATED_DATE: "Created Date"
            },
            DEFAULT_ROLES: {
                OWNER: "Owner",
                SUPER_ADMIN: "Super Admin",
                ADMIN: "Admin",
                USER: "User",
            },
            NOMINATIONS: {
                "edit-company-details": "Company Settings: Details",
                "edit-company-users": "Company Settings: Users",
                "edit-company-users-invites": "Company Settings: Users Invites",
                "edit-company-roles": "Company Settings: Roles",
                "edit-company-integrations": "Company Settings: Integrations",
                "files-management": "Files Management",
                "contracts-management": "Contracts Management",
                "contracts-deletion": "Contracts Deletion",
            }
        }
    },
    COMPANIES_INTEGRATIONS: {
        TITLE: "COMPANY INTEGRATIONS",
        DESCRIPTION: "Integrations of the current company: you can read, delete for integrations in the company",
        GDRIVE_INTEGRATION_TITLE: "Google Drive Export",
        ONEDRIVE_INTEGRATION_TITLE: "OneDrive Export",
        SHAREPOINT_INTEGRATION_TITLE: "SharePoint Export",
        SHAREPOINT_SITES_SELECTION: "SharePoint Sites Selection",
        GDRIVE_INTEGRATION_DESCRIPTION: "You can integrate your company account with Google Drive to export files on Google Drive",
        ONEDRIVE_INTEGRATION_DESCRIPTION: "You can integrate your company account with OneDrive to export files on OneDrive",
        SHAREPOINT_INTEGRATION_DESCRIPTION: "You can integrate your company account with SharePoint to export files on SharePoint",
        CONNECT: "Connect",
        CONNECTED: "Connected",
        REMOVE_CONNECTION: "Remove Connection",
        ACTIVE_INTEGRATION: "active account(s)",
    },
    CONTRACTS: {
        PAGE_TITLE: "Contract Overview",
        CONTRACT: {
            TITLE: "Contract:",
        },
        REPORTS: {
            ADD: "Add",
            NEW: "New",
            FILES: "FILES",
            DASHBOARD: "Dashboard",
            TEMPLATES: "Templates",
            CUSTOM_FORMS: "Custom Forms",
            CONTRACTS: "Contracts",
            STATUSES: "Statuses",
            CREATE_COMPANY: "Create company",
            CREATE_CONTRACT: "Create contract",
            ADD_USER_TO_COMPANY: "Add user to company",
            CONTRACT_TYPES: "Contract Types",
            MONTHLY_REPORT: "Monthly Contracts",
            TIMELINE: "Timeline",
            NO_DATA_TIMELINE: "No data for timeline",
        },
        REPORTS_TABLE: {
            TITLE: "Title",
            START_DATE: "Start Date",
            END_DATE: "End Date",
            RENEWAL_DATE: "Renewal Date",
            TYPE: "Type",
            PIPELINE: "Pipeline",
            CREATED_AT: "Created At",
        },
        TABLE: {
            TOP_TITLE: "Contracts",
            PAGE_TITLE: "CONTRACTS",
            PAGE_DESCRIPTION: "contracts listed.",
            ADD_NEW: "Add New",
            TITLE: "Title",
            START_DATE: "Start Date",
            END_DATE: "End Date",
            RENEWAL_DATE: "Renewal Date",
            EFFECTIVE_DATE: "Effective Date",
            COMPANIES: "Companies",
            COMPANIES_INTERNAL: "Company (Internal) Party",
            COMPANIES_EXTERNAL: "Counter Party",
            CONTACTS: "Contacts",
            ASSIGNED_USERS: "Assigned Users",
            PIPELINE: "Pipeline",
            STATUS: "Status",
            TYPE: "Type",
            CREATED_AT: "Created At",
            DELETE_ALL: "Delete All",
            DELETE_SELECTED: "Delete Selected",
            SEARCH: {
                PLACEHOLDER: 'Search contracts...',
                TITLE: 'Title',
                DESCRIPTION: 'Description',
                CUSTOM_FIELDS: 'Custom Fields'
            },
            FILTERS: {
                FILTERS: 'Filters',
                ADD_FILTERS: 'Add Filters',
                RESET_FILTERS: 'Reset Filters',
                WHERE: 'WHERE',
                AND: 'AND',
                OR: 'OR',
                CONTAINS: 'Contains',
                NOT_CONTAINS: 'Not Contains',
                DATE_RANGE: 'Date Range',
                SELECTION: 'Selection',
                FAVORITE_FILTERS: 'Favorite Filters',
                SAVE_FILTERS: 'Save Filters',
                MAKE_DEFAULT_FILTERS: 'Make Default',
                SEARCH_FILTERS: 'Search Filters',
                EDIT_FILTERS: 'Edit Filters',
                FILTER_TITLE: 'Filter Title',
                DELETE_FILTERS: 'Delete Filters',
                CANCEL: 'Cancel',
                ARE_YOU_SURE: 'Are you sure?',
                ARE_YOU_SURE_DELETE_FILTERS_DESCRIPTION: 'You are permanently deleting the filter. Are you sure you want to delete?',
                DELETE: 'Delete',
                SAVE: 'Save',
                DEFAULT_FILTERS: 'Default Filters',
                NO_DEFAULT_FILTERS: 'No Default Filters',
                NO_FAVORITE_FILTERS: 'No Favorite Filters',
                ALL_DATA_LOADED: 'All data loaded',
                FILTERS_SELECTED: 'Filters Selected',
                REMOVE_DEFAULT_FILTERS: 'Remove Default',
            },
            SORT_BY: {
                SORT_BY: 'Sort By'
            },
            COLUMNS: {
                COLUMNS: 'Columns'
            },
            EXPORT: {
                EXPORT: 'Export',
                EXPORT_TO_EXCEL: 'Export to Excel'
            },
            FOOTER: {
                TOTAL: 'Total',
                PER_PAGE: 'Per Page'
            }
        },
        OVERVIEW: {
            TITLE_ERROR: "Please provide a title for the contract",
            ACTIONS: {
                ACTIONS: "Actions",
                EDIT_CONTRACT: "Edit Contract",
                REMOVE_CONTRACT: "Remove Contract"
            },
            INFO_CARDS: {
                START_DATE: "Start Date",
                END_DATE: "End Date",
                EFFECTIVE_DATE: "Effective Date",
                RENEWAL_DATE: "Renewal Date",
                ASSIGNED_USERS: "Assigned Users"
            },
            ACTIVITY_FIELD: {
                FILES: "Files",
                REMINDER: "Reminder"
            },
            FILES: {
                FILES_DESCRIPTION: "When you add your files, you can see their activities in activity feed.",
                ADD_FILES: "Add Files",
                UPLOAD_FILES: "Upload Files",
                SELECT_UPLOADED_FILES: "Select Uploaded Files",
                ADDED_FILES: "Added Files",
                SHOW_ALL_FILES: "Show All Files",
                FILENAME: "Filename",
                UPLOADED_BY: "Uploaded By",
                ACTIONS: "Actions",
                NO_FILES: "No file has been found for this contract",
                SAVE_CHANGES: "Save Changes",
                EDIT: "Edit",
                CLOSE_EDIT_MODE: "Close Edit Mode",
                DOWNLOAD: "Download",
                DELETE: "Delete",
                GO_TO_PAGE: "Go to Page",
                TOTAL_FILES: "Total Files:",
                MAX_PAGE: "Max Page:",
                DELETE_FILE: "Delete File",
                DELETE_FILE_DESCRIPTION: "Are you sure about deletion of the file?",
            },
            REMINDER: {
                REMINDER_DESCRIPTION: "You can add reminder to not forget important things.",
                ADD_REMINDER: "Add Reminder",
                REMINDERS: "Reminders",
                PROVIDE_GUEST_NAME: "Please provide a guest name",
                PROVIDE_GUEST_SURNAME: "Please provide a guest surname",
                PROVIDE_GUEST_EMAIL: "Please provide a guest email",
                TITLE_LABEL: "Title",
                TITLE_PLACEHOLDER: "Enter a reminder title...",
                DATE_LABEL: "Reminder Date",
                DESCRIPTION_LABEL: "Description",
                DESCRIPTION_PLACEHOLDER: "Enter a reminder description...",
                GUESTS_LABEL: "Guests",
                ADD_USER: "Add User",
                ADD_OUTSOURCE_GUESTS: "Add Outsourced Guests",
                CANCEL: "Cancel",
                CREATE: "Create",
                NAME_LABEL: "Name",
                NAME_PLACEHOLDER: "Enter a guest name...",
                SURNAME_LABEL: "Surname",
                SURNAME_PLACEHOLDER: "Enter a guest surname...",
                EMAIL_LABEL: "Email",
                EMAIL_PLACEHOLDER: "Enter a guest email...",
                ADD_GUEST: "Add Guest",
                SEARCH_A_USER: "Search a user",
                NO_MORE: "No More",
                NO_DATA_FOUND_IN_THE_REMINDERS: "No data found in the reminders",
                CREATE_FIRST_REMINDER: "Create first reminder",
                NEW_REMINDER: "New Reminder",
                NEW_STATUS: "New Status",
                ADD_TO_CALENDAR: "Add to Calendar",
                ADD_TO_GOOGLE_CALENDAR: "Add to Google Calendar",
                ADD_TO_OUTLOOK_CALENDAR: "Add to Outlook Calendar",
                ADD_TO_OFFICE_360_CALENDAR: "Add to Office 365 Calendar",
                ARE_YOU_SURE: "Are you sure?",
                ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the reminder. Are you sure you want to delete?",
                REMOVE: "Remove"
            },
            DESCRIPTION: {
                TITLE: "Contract Description",
                DESCRIPTION_PLACEHOLDER: "Enter a contract description...",
                SAVE: "Save"
            },
            TAGS: {
                TAGS: "Tags",
                NO_TAGS: "Contract doesn't have tags",
                ADD_TAGS_TO_CONTRACT: " Add tags to contract",
                ADD_NEW_TAG_PLACEHOLDER: "Add a new tag...",
            },
            COMPANIES: {
                COMPANIES: "Companies",
                NO_COMPANY_FOUND: "Contract doesn't have any company",
                NO_MORE: "No More",
                ADD_COMPANY_TO_CONTRACT: "Add Company to Contract"
            },
            CONTACTS: {
                CONTACTS: "Contacts",
                ADD_CONTACT_TO_CONTRACT: "Add Contact to Contract",
                ALL_DATA_LOADED: "All data loaded",
            },
            CUSTOM_FIELDS: {
                CUSTOM_FIELDS: "Custom Fields",
            },
            ASSIGNED_USERS: {
                ASSIGNED_USERS: "Assigned Users",
                ASSIGN_USER_TO_CONTRACT: "Assign a user to contract",
                NO_ASSIGNED_USERS: "Contract doesn't have assigned users",
                ALL_DATA_LOADED: "All data loaded",
                SEARCH_PLACEHOLDER: "Search a user"
            },
            CONTRACT_TYPE: "Contract Type",
        },
        ADD_CONTRACT: {
            NEW_CONTRACT: "New Contract",
            NO_TITLE: "Please provide a title",
            NO_STATUS: "Please provide a status",
            NO_TYPE: "Please provide a type",
            PIPELINE: "Please provide a pipeline",
            NO_COMPANY: "Please provide a company",
            TITLE_LABEL: "Title",
            TITLE_PLACEHOLDER: "Enter a contract title...",
            DESCRIPTION_LABEL: "Description",
            DESCRIPTION_PLACEHOLDER: "Enter a contract description...",
            START_DATE_LABEL: "Start Date",
            END_DATE_LABEL: "End Date",
            EFFECTIVE_DATE_LABEL: "Effective Date",
            RENEWAL_DATE_LABEL: "Renewal Date",
            PIPELINE_LABEL: "Pipeline",
            PIPELINE_PLACEHOLDER: "Select a pipeline",
            STATUS_LABEL: "Status",
            STATUS_PLACEHOLDER: "Select a status",
            TYPE_LABEL: "Contract Type",
            TYPE_PLACEHOLDER: "Select a contract type",
            CANCEL: "Cancel",
            CREATE: "Create",
            MAKE_PRIVATE: "Make Private",
            MAKE_PUBLIC: "Make Public",
            PARTIES_INTERNAL: "Company (Internal) Party",
            PARTIES_EXTERNAL: "Counter Party",
            ASSIGNED_USERS: "Assigned Users",
        },
        EDIT_CONTRACT: {
            MODAL_TITLE: "Edit Contract",
            SOMETHING_IS_WRONG: "Something went wrong! Please refresh your page!",
            NO_TITLE: "Please provide a title",
            NO_STATUS: "Please provide a status",
            NO_TYPE: "Please provide a type",
            NO_COMPANY: "Please provide a company",
            TITLE_LABEL: "Title",
            TITLE_PLACEHOLDER: "Enter a contract title...",
            DESCRIPTION_LABEL: "Description",
            DESCRIPTION_PLACEHOLDER: "Enter a contract description...",
            START_DATE_LABEL: "Start Date",
            END_DATE_LABEL: "End Date",
            EFFECTIVE_DATE_LABEL: "Effective Date",
            RENEWAL_DATE_LABEL: "Renewal Date",
            PIPELINE_LABEL: "Pipeline",
            PIPELINE_PLACEHOLDER: "Select a pipeline",
            STATUS_LABEL: "Status",
            STATUS_PLACEHOLDER: "Select a status",
            TYPE_LABEL: "Contract Type",
            TYPE_PLACEHOLDER: "Select a contract type",
            CANCEL: "Cancel",
            SAVE: "Save",
            MAKE_PRIVATE: "Make Private",
            MAKE_PUBLIC: "Make Public"
        },
        KANBAN: {
            PAGE_TITLE: "Kanban",
            PIPELINES: "Pipelines",
            SWITCH_PIPELINE: "Switch Pipeline",
            EDIT_PIPELINE: "Edit Pipeline",
            MAKE_USER_DEFAULT: "Make User Default",
            ADD_NEW: "Add New",
            CONTRACT: "Contract",
            ALL_DATA_LOADED: "All data loaded",
            NO_CONTRACT_FOUND: "No contract found for this status",
            CONTRACT_CONTACTS: "Contract Contacts",
            CONTRACT_COMPANIES: "Contract Companies",
        }
    },
    CONTRACTS_COMPANIES: {
        PAGE_TITLE: "Contracts Companies",
        TITLE: "Company Name/Title",
        TITLE_COMPANY: "Company Name/Title",
        TITLE_CONTACT: "Name Surname",
        DESCRIPTION: "Description",
        CREATED_DATE: "Created Date",
        ACTIONS: "Actions",
        EDIT: "Edit",
        DELETE: "Delete",
        CONTRACTS_COMPANIES: "CONTRACT COMPANIES",
        CONTRACTS_COMPANIES_DESCRIPTION: "contract companies listed.",
        ADD_NEW: "Add New",
        NEW_CONTRACT_COMPANY: "New Contract Company",
        NO_TITLE: "Please provide a title",
        NO_COMPANY: "Please refresh your page!",
        TITLE_LABEL: "Company Name/Title",
        TITLE_LABEL_COMPANY: "Company Name/Title",
        TITLE_LABEL_CONTACT: "Name Surname",
        TITLE_PLACEHOLDER: "Enter a contract company title...",
        TITLE_PLACEHOLDER_COMPANY: "Enter a contract company title...",
        TITLE_PLACEHOLDER_CONTACT: "Enter a contract contact name surname...",
        DESCRIPTION_LABEL: "Description",
        DESCRIPTION_PLACEHOLDER: "Enter a contract company description...",
        TAGS_LABEL: "Tags",
        TAGS_PLACEHOLDER: "Enter contract company tags...",
        CANCEL: "Cancel",
        CREATE: "Create",
        SAVE: "Save",
        EDIT_CONTRACT_COMPANY: "Edit Company",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the contract company. Are you sure you want to delete?",
        TAX_ID: "Tax ID",
        TC_ID: "T.C. ID",
        TAX_OFFICE: "Tax Office",
        COMPANY_ADDRESS: "Company Address",
        ADDRESS: "Address",
        CONTACT_NAME: "Contact Name",
        CONTACT_EMAIL: "Contact Email",
        EMAIL: "E-mail",
    },
    CONTRACTS_CONTACTS: {
        PAGE_TITLE: "Contract Contacts",
        FULLNAME: "Full Name",
        DESCRIPTION: "Description",
        CREATED_DATE: "Created Date",
        CREATED_BY: "Created By",
        ACTIONS: "Actions",
        CONTRACTS_CONTACTS: "CONTRACT CONTACTS",
        CONTRACTS_CONTACTS_DESCRIPTION: "contract contacts listed.",
        ADD_NEW: "Add New",
        NEW_CONTRACT_CONTACT: "New Contract Contact",
        NO_NAME: "Please provide a valid name",
        NO_SURNAME: "Please provide a valid surname",
        NO_COMPANY: "Please refresh your page!",
        NAME_LABEL: "Name",
        NAME_PLACEHOLDER: "Enter a contract contact name...",
        SURNAME_LABEL: "Surname",
        SURNAME_PLACEHOLDER: "Enter a contract contact surname...",
        DESCRIPTION_LABEL: "Description",
        DESCRIPTION_PLACEHOLDER: "Enter a contract company description...",
        DELETE: "Delete",
        TAGS_LABEL: "Tags",
        TAGS_PLACEHOLDER: "Enter contract company tags...",
        CANCEL: "Cancel",
        CREATE: "Create",
        SAVE: "Save",
        EDIT_CONTRACT_CONTACT: "Edit Contact",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the contact. Are you sure you want to delete?"
    },
    CONTRACTS_CUSTOM_FIELDS: {
        PAGE_TITLE: "Custom Fields",
        TITLE: "Title",
        TITLE_PLACEHOLDER: "Enter a custom field title...",
        INTERNAL_NAME: "Internal Name",
        INTERNAL_NAME_PLACEHOLDER: "Enter a custom field internal name...",
        FIELD_TYPE: "Field Type",
        REQUIRED: "Required",
        CREATED_DATE: "Created Date",
        ACTIONS: "Actions",
        CUSTOM_FIELDS: "CUSTOM FIELDS",
        CUSTOM_FIELDS_DESCRIPTION: "custom fields listed.",
        ADD_NEW: "Add New",
        CUSTOM_FIELDS_COMPONENT_NAMES: {
            TEXT: "Text",
            TEXTAREA: "Textarea",
            NUMBER: "Number",
            EMAIL: "Email",
            DATE: "Date",
            TIME: "Time",
            SINGLE_SELECTION: "Single Selection"
        },
        SELECT_CUSTOM_COMPONENT_FIELD: "Select custom field type",
        THIS_IS_DEFAULT: "This is default",
        FIELD_TYPE_REQUIRED: "Field Type (Required)",
        FIELD_TYPE_FEEDBACK: "Please provide a field type",
        FIELD_REQUIREMENT: "Field Requirement",
        CREATE: "Create",
        SAVE: "Save",
        CANCEL: "Cancel",
        DELETE: "Delete",
        EDIT_CUSTOM_FIELD: "Edit Custom Field",
        WARNING: "Warning !",
        WARNING_MESSAGE: "All contract types will be affected by this change.",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting it. Are you sure you want to delete?",
        OPTIONS: "Options",
        OPTION_TITLE: "Option Title",
        OPTION_VALUE: "Option Value",
        OPTION_INTERNAL_NAME: "Option Internal Name",
        ADD_OPTION: "Add Option",
        REMOVE_OPTION: "Remove Option",
        DELETE_OPTION: "Delete Option",
    },
    CONTRACTS_PIPELINES: {
        PAGE_TITLE: "Pipelines",
        PIPELINES: "Pipelines",
        NO_TITLE: "Please provide a title",
        NEW_PIPELINE: "New Pipeline",
        LOADING: "Loading...",
        STATUSES: "Statuses",
        TITLE: "Title",
        TITLE_PLACEHOLDER: "Enter a pipeline title...",
        DESCRIPTION: "Description",
        DESCRIPTION_PLACEHOLDER: "Enter a pipeline description...",
        CANCEL: "Cancel",
        CREATE: "Create",
        SAVE: "Save",
        EDIT: "Edit",
        CHECK_STATUSES: "Check Statuses",
        PIPELINE_STATUSES: "Pipeline Statuses",
        AVAILABLE_STATUSES: "Available Statuses",
        SELECT_NEW_PIPELINE: "Select New Pipeline",
        SELECT_NEW_STATUS: "Select New Status",
        MIGRATE_YOUR_CONTRACTS: "Migrate Your Contracts",
        EXISTING_CONTRACTS_WARNING: "contracts exist in this contract status. You must migrate your contracts before deleting.",
        MIGRATE_ALL: "Migrate All",
        PIPELINE_TITLE_LABEL: "Pipeline Title",
        PIPELINE_TITLE_PLACEHOLDER: "Enter a pipeline title...",
        DRAG_AND_DROP_DESCRIPTION: "Drag and drop statuses\n to create a pipeline",
        STATUS_NOT_FOUND: "No status found in the system",
        DEFAULT: "Default",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the pipeline. Are you sure you want to delete?",
        ADD_PIPELINE: "Add Pipeline",
        KANBAN_VIEW: "Kanban View",
        BOARD_DESCRIPTION: "List the pipeline's contracts on board",
        EDIT_PIPELINE: "Edit Pipeline",
        DELETE_PIPELINE: "Delete Pipeline",
        ARE_YOU_SURE_STATUS: "Are you sure?",
        ARE_YOU_SURE_STATUS_MODAL_DESCRIPTION: "You are permanently deleting it. Are you sure you want to delete?",
        USER_DEFAULT: "User Default",
        SYSTEM_DEFAULT: "System Default",

    },
    CONTRACTS_STATUSES: {
        PAGE_TITLE: "Statuses",
        ADD_STATUS: "Add Status",
        NEW_STATUS: "New Status",
        EDIT_STATUS: "Edit Status",
        DELETE_STATUS: "Delete Status",
        NO_TITLE: "Please provide a title",
        TITLE_LABEL: "Title",
        TITLE_PLACEHOLDER: "Enter a status title...",
        DESCRIPTION_LABEL: "Description",
        DESCRIPTION_PLACEHOLDER: "Enter a status description...",
        CANCEL: "Cancel",
        CREATE: "Create",
        DELETE: "Delete",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the status. Are you sure you want to delete?"
    },
    CONTRACTS_TYPES: {
        SAVED: "Saved",
        SAVING: "Saving...",
        PAGE_TITLE: "Contract Types",
        CONTRACT_TYPES: "CONTRACT TYPES",
        NO_TITLE: "Please provide a title",
        TITLE: "Title",
        CUSTOM_FIELDS: "Custom Fields",
        CREATED_DATE: "Created Date",
        ACTIONS: "Actions",
        CONTRACT_TYPES_DESCRIPTION: "contract types listed.",
        ADD_NEW: "Add New",
        NEW_CONTRACT_TYPE: "New Contract Type",
        CREATE: "Create",
        EDIT_CONTRACT_TYPE: "Edit Contract Type",
        SAVE: "Save",
        DEFAULT_FIELDS: {
            TITLE: "Title",
            STATUS: "Status",
            PIPELINE: "Pipeline",
            USER: "User",
            CREATED_DATE: "Created Date",
            UPDATED_DATE: "Updated Date",
            EFFECTIVE_DATE: "Effective Date",
            RENEWAL_DATE: "Renewal Date",
        },
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the contract type. Are you sure you want to delete?",
        ARE_YOU_SURE_CUSTOM_FIELD_MODAL_DESCRIPTION: "You are permanently deleting the custom field. Are you sure you want to delete?",
        DELETE: "Delete",
        CANCEL: "Cancel",
        TITLE_LABEL: "Enter a title",
        TITLE_PLACEHOLDER: "Enter a contract type title..."
    },
    FILES: {
        PAGE_TITLE: "Files Management",
        FILE_MANAGER: "File Manager",
        SEARCH_INPUT_PLACEHOLDER: "Search for files",
        UPLOAD_FILES: "Upload Files",
        FILES: "Files",
        HIDE_LONG_TAGS: "Hide Long Tags",
        SHOW: "Show",
        MORE_TAGS: "more tags exists",
        NONE: "None",
        NO_TAGS: "No Tags",
        FILENAME: "Filename",
        CREATED_DATE: "Created Date",
        UPLOADED_BY: "Uploaded By",
        CONTRACT: "Contract",
        TAGS: "Tags",
        SIZE: "Size",
        TYPE: "Type",
        VERSION: "Version",
        SOURCE: "Source",
        ACTIONS: "Actions",
        EXPORT: "Export",
        SAVE_CHANGES: "Save Changes",
        CANCEL: "Cancel",
        EDIT: "Edit",
        CLOSE_EDIT_MODE: "Close Edit Mode",
        DELETE: "Delete",
        DOWNLOAD: "Download",
        SEPERATE_TAGS_WITH_COMMAS: "Separate tags with commas",
        DELETE_FILE: "Delete File",
        DELETE_FILE_DESCRIPTION: "Are you sure about deletion of the file?",
        TOTAL_FILES: "Total Files",
        MAX_PAGES: "Max Pages",
        NO_DATA_FOUND: "No Data Found",
        VERSIONS: "versions",
        VERSIONS_POPUP: {
            FILENAME: "Filename",
            VERSION: "Version",
            CREATED_DATE: "Created Date",
            UPLOADED_BY: "Uploaded By",
            ACTIONS: "Actions",
            REMOVE_FROM_VERSIONS: "Remove from versions",
            DOWNLOAD: "Download File",
            DELETE: "Delete File",
        },
        MIMETYPES: {
            'application/rtf': 'Rich Text Format(.rtf)',
            'application/vnd.oasis.opendocument.text': 'OpenDocument Format(.odt)',
            'text/html': 'Web Page(.html)',
            'application/pdf': 'PDF Document(.pdf)',
            'application/epub+zip': 'EPUB Publication(.epub)',
            'application/zip': 'Web Page(.html, zipped)',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Microsoft Word(.docx)',
            'text/plain': 'Text Plain(.txt)',
        }
    },
    TEMPLATES: {
        PAGE_TITLE: "Templates",
        SEARCH_INPUT_PLACEHOLDER: "Search for files",
        UPLOAD_FILES: "Upload Files",
        TEMPLATES: "Templates",
        HIDE_LONG_TAGS: "Hide Long Tags",
        SHOW: "Show",
        MORE_TAGS: "more tags exists",
        NONE: "None",
        NO_TAGS: "No Tags",
        TEMPLATE_NAME: "Template Name",
        DOCUMENT_NAME: "Document Name",
        CREATED_DATE: "Created Date",
        UPLOADED_BY: "Uploaded By",
        CONTRACT_TYPE: "Contract Type",
        TAGS: "Tags",
        SIZE: "Size",
        TYPE: "Type",
        DOCUMENT_TYPE: "Document Type",
        SOURCE: "Source",
        ACTIONS: "Actions",
        SAVE_CHANGES: "Save Changes",
        CANCEL: "Cancel",
        EDIT: "Edit",
        CLOSE_EDIT_MODE: "Close Edit Mode",
        DELETE: "Delete",
        DOWNLOAD: "Download",
        SEPERATE_TAGS_WITH_COMMAS: "Separate tags with commas",
        DELETE_FILE: "Delete Template",
        DELETE_FILE_DESCRIPTION: "Are you sure about deletion of the template?",
        TOTAL_FILES: "Total Files",
        MAX_PAGES: "Max Pages",
        NO_DATA_FOUND: "No Data Found",
        EDIT_TEMPLATE: "Edit template",
        CREATE_DOCUMENT: "Use to create document",
        CREATE_TEMPLATE: "Create a Template",
        DOCUMENT: 'Document',
        SPREADSHEET: 'Spreadsheet',
        PRESENTATION: 'Presentation',
        TEMPLATE_EDITOR: 'Template Editor',
        DOCUMENT_EDITOR: 'Document Editor',
        PROVIDE_DOCUMENT_TITLE: 'Provide a document name',
        CREATING_DOCUMENT: 'Creating Document',
        CREATING_TEMPLATE: 'Creating Template',
    },
    USERS: {
        MY_ACCOUNT: "My Account",
        DASHBOARD: "Dashboard",
        PROFILE_TITLE: "Profile",
        CONTACT_INFORMATION: "Contact Information",
        EMAIL: "Email:",
        PHONENUMBER: "Phone:",
        PROFILE: {
            GENERAL: {
                TAB_TITLE: "General",
                GENERAL: "General",
                GENERAL_DESCRIPTION: "In this section, you can edit your general information.",
                NAME_LABEL: "Name:",
                NAME_PLACEHOLDER: "Enter your name...",
                SURNAME_LABEL: "Surname:",
                SURNAME_PLACEHOLDER: "Enter your surname...",
                USERNAME_LABEL: "Username:",
                USERNAME_PLACEHOLDER: "Enter your username...",
                PHONENUMBER_LABEL: "Phone Number:",
                PHONENUMBER_PLACEHOLDER: "5XX XXX XX XX",
                SAVE_CHANGES: "Save Changes",
            },
            CREDENTIALS: {
                TAB_TITLE: "Credentials",
                CREDENTIALS: "Login / User Credentials",
                CREDENTIALS_DESCRIPTION: "In this section, you can edit your login and user information for Jetlexa Panel.",
                EMAIL_LABEL: "Email:",
                EMAIL_PLACEHOLDER: "Enter you email address and confirm it",
                EMAIL_FEEDBACK: "Please provide a valid email.",
                OLD_PASSWORD_LABEL: "Old Password:",
                OLD_PASSWORD_PLACEHOLDER: "Enter your old password...",
                OLD_PASSWORD_FEEDBACK: "Please be careful about your password information",
                NEW_PASSWORD_LABEL: "New Password:",
                NEW_PASSWORD_PLACEHOLDER: "Enter your new password...",
                NEW_PASSWORD_FEEDBACK: "Please be careful about your password information",
                REENTER_NEW_PASSWORD_LABEL: "Re-enter New Password:",
                REENTER_NEW_PASSWORD_PLACEHOLDER: "Re-enter your new password...",
                REENTER_NEW_PASSWORD_FEEDBACK: "Please be careful about your password information",
                SAVE_CHANGES: "Save Changes",
            },
            DELETION: {
                TAB_TITLE: "Deletion",
                DELETE_YOUR_ACCOUNT: "Delete your account",
                DELETE_YOUR_ACCOUNT_DESCRIPTION: "You can delete your account from Jetlexa. Your account information will be stored for 30 days after delete command. You can cancel deletion by logging in back",
                DELETE_YOUR_ACCOUNT_INFORM: "You can type 'permanent-delete' into input box to delete your account",
                DELETE_CHECK: "Delete Check:",
                DELETE_CHECK_FEEDBACK: "Please type 'permanent-delete' into input box to delete your account",
                DELETE_ACCOUNT: "Delete Account",
            }
        }
    },
    USERS_INTEGRATIONS: { //done
        TITLE: "USER INTEGRATIONS",
        DESCRIPTION: "You can manage integrations of your company",
        GDRIVE_INTEGRATION_TITLE: "Google Drive Import",
        ONEDRIVE_INTEGRATION_TITLE: "OneDrive Import",
        SHAREPOINT_INTEGRATION_TITLE: "SharePoint Import",
        GDRIVE_INTEGRATION_DESCRIPTION: "You can integrate your account with Google Drive to import files on Google Drive",
        ONEDRIVE_INTEGRATION_DESCRIPTION: "You can integrate your account with OneDrive to import files on OneDrive",
        SHAREPOINT_INTEGRATION_DESCRIPTION: "You can integrate your account with SharePoint to import files on SharePoint",
        CONNECT: "Connect",
        CONNECTED: "Connected",
        REMOVE_CONNECTION: "Remove Connection",
    },
    POPUPS: {
        FILE_PERMISSON: {
            DRIVE: {
                TITLE: "Google Drive Permission",
                READ: "Read",
                WRITE: "Write",
                OWNER: "Owner",
                DOMAIN: "Domain",
                USER: "User",
                ADD_PERMISSION: "Add Permission",
                ADD_USER: "Add User",
                ADD: "Add",
                NOTIFY_USER: "Notify User",
                PEOPLE_WITH_ACCESS: "People with access",
                INVALID_EMAIL: "Please provide a valid email address",
            }
        },
        ACCEPT_OR_CLOSE: {
            ACCEPT: "Accept",
            CANCEL: "Cancel",
        },
        ADD_CONTRACT: {
            NEW_CONTRACT: "New Contract",
            NEXT: "Next",
        },
        ADD_CUSTOM_FIELD: {
            NEW_CUSTOM_FIELD: "New Custom Field"
        },
        ADD_USER_TO_COMPANY: {
            INVITE_USER_TO_COMPANY: "Invite User to Company",
            INVITE_USER_TO_COMPANY_DESCRIPTION: "Invite your teammates and start planing your business flow.",
            NO_EMAIL_ADDRESS_ADDED: "No email address added",
            INVITE_VIA_EMAIL: "Invite via Email",
            ADD_NEW_EMAIL: "Add new email",
            PROVIDE_VALID_EMAIL: "Please provide a valid email",
            SELECT_ROLES: "Select Roles from the list...",
            FINISH: "Finish",
        },
        CDN: {
            SELECT_SOURCE: 'Select Sources to transfer files',
            UPLOAD: 'Upload File(s)',
            ADD_NEW: 'Add New',
            FROM_COMPUTER: 'From Computer',
            FROM_CLOUD: 'From Cloud',
            JETLEXA_ON_PREM: 'Jetlexa On-premises Storage',
            AWS_S3: 'Amazon S3 Storage',
            GOOGLE_DRIVE: 'Google Drive Storage',
            ONE_DRIVE: 'OneDrive Storage',
            SHARE_POINT: 'SharePoint Storage',
            UPLOADING_TITLE: 'Files are uploading to source',
            UPLOADED_TITLE: "Files are uploaded, you can choose files' contract, file's version or add tags",
            FINISH: 'Finish',
            IMPORTING: 'Files are being downloaded from the imported source, wait till download process is completed...',
            FROM_CLOUD_TITLE: 'Select source to import files from a cloud storage (Up to 20 files)',
            CONTINUING_UPLOAD: 'File are uploading to selected storages...',
            COMPLETED_UPLOAD: 'Files are succesfully uploaded to selected storages',
            INTEGRATE_WITH_GDRIVE: 'Please contact your system admin to integrate with Google Drive',
            INTEGRATE_WITH_ONEDRIVE: 'Please contact your system admin to integrate with OneDrive',
            INTEGRATE_WITH_SHAREPOINT: 'Please contact your system admin to integrate with SharePoint',
            SELECT_SOURCE_WARNING: "You need to select a source to upload file(s)",
            SOMETHING_IS_WRONG: 'Something is wrong with our import system, try again later',
            ONEDRIVE_IMPORT_ACCOUNT_PROBLEM: 'OneDrive import account must be opened on onedrive.com',
        },
        COMPANY_ONBOARDING: {
            NO_COMPANY_FOUND: "Please create or join a company",
            COMPANY_ONBOARDING: "Company Onboarding",
            TITLE_LABEL: "Company Title:",
            TITLE_PLACEHOLDER: "Enter your company title...",
            TITLE_FEEDBACK: "Please provide a valid company title",
            WEBSITE_LABEL: "Company Website:",
            WEBSITE_PLACEHOLDER: "Enter your company website...",
            WEBSITE_FEEDBACK: "Please provide a valid company website",
            DESCRIPTION_LABEL: "Company Description:",
            DESCRIPTION_PLACEHOLDER: "Enter your company description...",
            DESCRIPTION_FEEDBACK: "Please provide a valid company description",
            WHAT_IS_YOUR_ROLE_LABEL: "What is your role in the company?",
            WHAT_IS_YOUR_ROLE_PLACEHOLDER: "Enter your role in the company...",
            WHAT_IS_YOUR_ROLE_FEEDBACK: "Please provide a valid role in the company",
            WHAT_TO_DO_WITH_SYSTEM_LABEL: "What to do with the system?",
            WHAT_TO_DO_WITH_SYSTEM_PLACEHOLDER: "Enter what to do with the system...",
            WHAT_TO_DO_WITH_SYSTEM_FEEDBACK: "Please provide a valid what to do with the system",
            SIZE_OF_COMPANY_LABEL: "What is the size of your company?",
            SIZE_OF_COMPANY_PLACEHOLDER: "Enter the size of your company...",
            SIZE_OF_COMPANY_FEEDBACK: "Please provide a valid size of your company",
            USAGE_SIZE_LABEL: "How many people will use the system?",
            USAGE_SIZE_PLACEHOLDER: "Enter the number of people using the system...",
            USAGE_SIZE_FEEDBACK: "Please provide a valid number of people using the system",
            NEXT: "Next",
            PREVIOUS: "Previous",
            FINISH: "Finish",
            JOIN_COMPANY: "Join an existing company and start working !",
            CREATE_COMPANY: "Create a new company and start onboarding !"
        },
        COMPANY_SWITCH: {
            COMPANY_SWITCH: "Choose Company To Continue With",
            CHOOSE_COMPANY_DESCRIPTION: "Choose your current company to start working !",
            NO_COMPANY_FOUND: "No company found",
        },
        ROLES_MANAGEMENT: {
            EDIT_ROLE: "Edit Role",
            DELETE_ROLE: "Delete Role",
            SAVE: "Save",
            FIELD: "Field",
            VALUE: "Value",
            DELETE_ROLE_DESCRIPTION: "Are you sure about deletion of the role?",
            FIELDS: {
                index: "Index",
                _id: "Unique ID",
                title: "Role Title",
                company: "Company",
                nominations: "Nominations",
                createdAt: "Created Date"
            },
            ACCEPT_OR_CLOSE_TITLE: "Delete Role",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Are you sure about deletion of the role?"
        },
        ROLES_NEW: {
            ADD_NEW_ROLE: "Add New Role To Your Company",
            TITLE_LABEL: "Role's Title",
            TITLE_PLACEHOLDER: "Enter your role's title...",
            TITLE_FEEDBACK: "Please provide a valid role's title",
            NOMINATIONS_OF_ROLE_LABEL: "Nominations for the role (Required)",
            NOMINATIONS_OF_ROLE_PLACEHOLDER: "Select nominations for the role",
            CREATE: "Create",
        },
        UPLOAD_FILES: {
            UPLOAD_STATUS: "Upload Status",
            FILENAME: "Filename",
            CONTRACT: "Contract",
            VERSION_OF: "Version of",
            TAGS: "Tags",
            FILE_SIZE: "File Size",
            LOADING: "Loading...",
            TAGS_PLACEHOLDER: "Enter tags separated by comma",
            SEPERATE_TAGS_WITH_COMMAS: "Separate tags with commas",
            COMPANY_NOT_INTEGRATED_WITH_DRIVE: "Company is not integrated with Google Drive",
            COMPANY_NOT_INTEGRATED_WITH_DRIVE_DESCRIPTION: "Please contact your company administrator to integrate Google Drive.",
            UPLOAD_FILES_MAX_20: "Upload your files (Maximum 20 files)",
            ERROR: "Error",
            UPLOAD: "Upload",
            FINISH: "Finish",
            UPLOAD_WINDOW_GETTING_READY_FOR_YOU: "Upload window is getting ready for you...",
            DROP_FILES_OR_CLICK: "Drop files here or click to upload.",
            DROP_FILES_OR_CLICK_DESCRIPTION: "You can upload up to 20 files in this screen by dragging or clicking",
            FILES_ARE_BEING_DOWNLOADED_FROM_DRIVE: "Files are being downloaded from your Google Drive and starting a upload procedure.",
            INTEGRATION_COMPLETED_GOOGLE: "Integration complete. Now upload from Google Drive.",
            INTEGRATION_COMPLETED_ONEDRIVE: "Integration complete. Now upload from OneDrive.",
            INTEGRATION_COMPLETED_SHAREPOINT: "Integration complete. Now upload from SharePoint.",
            ADD_FILES_FROM_GOOGLE_DRIVE: "Add files from Google Drive",
        },
        USERS_INVITES_MANAGEMENT: {
            UPDATE_CURRENT_USER_INVITE: "Update Current User Invite",
            DELETE_INVITE: "Delete Invite",
            FIELD: "Field",
            VALUE: "Value",
            SAVE: "Save",
            FIELDS: {
                index: "Index",
                _id: "Unique ID",
                email: "Email",
                createdAt: "Created Date",
                inviteCode: "Invite Code",
                status: "Status",
            },
            ACCEPT_OR_CLOSE_TITLE: "Delete Invite",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Are you sure about deletion of the invite?",
        },
        USERS_MANAGEMENT: {
            EDIT_COMPANY_USER: "Edit Company User",
            REMOVE_USER: "Remove User",
            SAVE: "Save",
            FIELD: "Field",
            VALUE: "Value",
            SELECT_ROLES_PLACEHOLDER: "Select roles for the user",
            FIELDS: {
                index: "Index",
                _id: "Unique ID",
                name: "Name",
                surname: "Surname",
                email: "Email",
                phoneNumber: "Phone Number",
                createdAt: "Created Date",
                username: "Username",
                role: "Role"
            },
            DEFAULT_FILE_PERMISSIONS: {
                READ: "Read",
                EDIT: "Edit",
                NOT_ALLOWED: "Not Allowed",
                OWNER: "Owner"
            },
            ACCEPT_OR_CLOSE_TITLE: "Remove User From Company",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Are you sure about removal of the user from the company?",
        }
    },
    SELECTS: {
        CONTRACTS: {
            SEARCH_CONTRACTS: "Select Contract",
            SEARCH_COMPANY: "Select Companies",
            CONTRACT_CHANGE_SUCCESS: "Contract change is successful",
        },
        FILES: {
            SEARCH_FILES: "Select File",
        }
    },
    UNAUTHORIZED_PAGES: {
        UNAUTH_LAYOUT: {
            DESCRIPTION: "It is the easiest, fastest and safest platform for CLM",
            AUTHOR: "JETLEXA TEAM"
        },
        LOGIN: {
            WELCOME_BACK: "Welcome Back!",
            LOGIN_DESCRIPTION: "Enter your email address and password to access JETLEXA",
            DONT_HAVE_ACCOUNT: "Don't have an account?",
            SIGN_UP: "Register",
            EMAIL_FEEDBACK: "Please provide a valid email",
            PASSWORD_FEEDBACK: "Please provide a valid password",
            PASSWORD_OR_EMAIL_INCORRECT: "Your password or email is incorrect.",
            PLEASE_LOGIN: "Please login or",
            REGISTER: "register",
            TO_CONTINUE_WITH: "to continue with your invite",
            LOGIN_SUCCESSFUL: "Logged in successfully, redirecting...",
            EMAIL_LABEL: "Email Address",
            EMAIL_PLACEHOLDER: "Enter your email address...",
            PASSWORD_LABEL: "Password",
            PASSWORD_PLACEHOLDER: "Enter your password...",
            LOGIN: "Login",
            REMEMBER_ME: "Remember me",
            FORGOT_PASSWORD: "Forgot your password?",
        },
        LOGOUT: {
            SEE_YOU_AGAIN: "Your logout is successful !",
            DESCRIPTION: "You successfully logged out, you can login at any time visiting JETLEXA",
            BACK_TO: "Back to",
            LOG_IN: "Login",
        },
        REGISTER: {
            CREATE_ACCOUNT: "Create Your Account",
            CREATE_ACCOUNT_DESCRIPTION: "Create your account and start your company onboarding",
            NAME_LABEL: "First Name:",
            NAME_PLACEHOLDER: "Enter your first name...",
            NAME_FEEDBACK: "Please provide a valid first name",
            SURNAME_LABEL: "Last Name:",
            SURNAME_PLACEHOLDER: "Enter your last name...",
            SURNAME_FEEDBACK: "Please provide a valid last name",
            PHONENUMBER_LABEL: "Phone Number:",
            PHONENUMBER_PLACEHOLDER: "54X XXX XX XX",
            PHONENUMBER_FEEDBACK: "Please provide a valid phone number",
            EMAIL_LABEL: "Email Address:",
            EMAIL_PLACEHOLDER: "Enter your email address...",
            EMAIL_FEEDBACK: "Please provide a valid email",
            PASSWORD_LABEL: "Password:",
            PASSWORD_PLACEHOLDER: "Enter your password...",
            PASSWORD_FEEDBACK: "Please provide a valid password",
            PASSWORD_MINIMUM_LENGTH_FEEDBACK: "Your password must be longer than 5 characters.",
            PASSWORD_MAXIMUM_LENGTH_FEEDBACK: "Your password must not be longer than 25 characters.",
            PASSWORD_MINIMUM_CHARACTER_FEEDBACK: "Your password must contain at least 6 characters.",
            PASSWORD_MINUMUM_ONE_UPPERCASE_ONE_LOWER_CASE_FEEDBACK: "Your password must contain one uppercase, one lowercase",
            PASSWORD_SPECIAL_CHARACTER_FEEDBACK: "Your password must contain at least one special characters",
            PASSWORD_NUMBER_FEEDBACK: "Your password must contain at least one number",
            ACCEPT_TERMS_AND_SERVICES_LABEL: "I accept the terms and services",
            ACCEPT_TERMS_AND_SERVICES_FEEDBACK: "Please accept the terms and services",
            REGISTER: "Register",
            SHOW_PASSWORD: "Show Password",
            HIDE_PASSWORD: "Hide Password",
            ALREADY_HAVE_ACCOUNT: "Already have an account?",
            LOGIN: "Login",
            ALERT_REGISTRATION_COMPLETE: "Registrations is successful, you will be redirected to next page",
            ALERT_INVITE_CODE: "Please register to continue with your invite",
            ATTEMPTED: "Attempted:",
        },
        CONFIRM: {
            TITLE: "Confirm your Email address",
            DESCRIPTION: "Your account has been successfully registered. To complete the verification process, please check your email for a validation request.",
            BACK_TO: "Back to",
            LOGIN: "Login"
        },
        CONFIRM_SUCCESSFUL: {
            GO_TO: "Go to",
            MAIL_CONFIRMATION_SUCCESS: "Mail Confirmation Successful!",
            DESCRIPTION: "Your mail confirmed is successful, you can go to JETLEXA"
        },
        CONFIRM_FAILED: {
            GO_TO: "Go to",
            LOGIN: "Login",
            MAIL_CONFIRMATION_FAILED: "Mail Confirmation Failed!",
            DESCRIPTION: "Your mail confirmation is failed, you can check your mail box for the correct url or contact the support via button under the page"
        },
        FORGET_PASSWORD: {
            TITLE: "Forget Password",
            DESCRIPTION: "Enter your email address and we'll send you an email with instructions to reset your password.",
            GO_TO: "Go to",
            LOGIN: "Login",
            EMAIL_LABEL: "Email Address",
            EMAIL_PLACEHOLDER: "Enter your email address...",
            EMAIL_FEEDBACK: "Please provide a valid email",
            ALERT_SUCCESSFULLY_SENT: "Email is successfully sent to email address. Please click the link to proceed to next step",
            SEND: "Send Email",
        },
        RESET_PASSWORD: {
            CHANGE_PASSWORD: "Change Password",
            CHANGE_PASSWORD_DESCRIPTION: "By filling the form below, you can change your current password and log in to the JETLEXA",
            BACK_TO: "Back to",
            LOGIN: "Login",
            PASSWORDS_DOESNT_MATCH: "Password doesn't match",
            PASSWORD_LABEL: "Password:",
            CHECK_PASSWORD_LABEL: "Re-enter Password:",
            PASSWORD_PLACEHOLDER: "Enter your password...",
            CHECK_PASSWORD_PLACEHOLDER: "Enter your password again...",
            PASSWORD_FEEDBACK: "Please provide a valid password",
            PASSWORD_MINIMUM_LENGTH_FEEDBACK: "Your password must be longer than 5 characters.",
            PASSWORD_MAXIMUM_LENGTH_FEEDBACK: "Your password must not be longer than 25 characters.",
            PASSWORD_MINIMUM_CHARACTER_FEEDBACK: "Your password must contain at least 6 characters.",
            PASSWORD_MINUMUM_ONE_UPPERCASE_ONE_LOWER_CASE_FEEDBACK: "Your password must contain one uppercase, one lowercase",
            PASSWORD_SPECIAL_CHARACTER_FEEDBACK: "Your password must contain at least one special characters",
            PASSWORD_NUMBER_FEEDBACK: "Your password must contain at least one number",
            ALERT_CHANGE_PASSWORD: "You can change your password by filling form below",
            SHOW_PASSWORD: "Show Password",
            HIDE_PASSWORD: "Hide Password"
        },
        LOCK_SCREEN: {
            HI: "Hi, ",
            DESCRIPTION: "Re-enter your password to access JETLEXA",
            PASSWORD_LABEL: "Password:",
            PASSWORD_PLACEHOLDER: "Enter your password...",
            PASSWORD_FEEDBACK: "Please provide a valid password",
            LOGIN: "Login",
            NOT_YOU: "Not you? Go to",
            ALERT_LOGIN_SUCCESSFUL: "Logged in successfully, redirecting to JETLEXA...",
            ATTEMPTED: "Attempted:",
            MESSAGE: "Your password or email is incorrect."
        }
    },
    MAIL_INBOX: {
        TITLE: "Inbox",
        NEW_CONNECTION: "Connect",
        DISCONNECT: "Disconnect",
        ALL: "All",
        IMPORTED: "Imported",
        MAIL_TEXT: "Mail Text",
        IMPORT: "Import",
        SELECT_EMAIL: "Select an email",
        IMPORT_SELECTED_FILES: "Import Selected Files",
        CONNECT_TO_EMAIL: "Connect to Email",
        ALL_DATA_LOADED: "All data loaded",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Are you sure you want to disconnect this email?",
        UPLOADING_FILES: "Uploading files",
        UPLOADING_FILES_DESCRIPTION: "Uploading selected files please wait...",
        CANCEL: "Cancel",
        DELETE: "Delete",
        FILES_UPLOADED_SUCCESSFULLY: "Files uploaded successfully",
        MAXIMUM_20_FILES: "You can upload maximum 20 files",
    },
    CUSTOMFORMS: {
        CUSTOMFORMS_EDIT_POPUP: {
            FORM_STATUS_ACTIVE: 'Active',
            FORM_STATUS_PASSIVE: 'Passive',
            FORM: 'Form',
            TEMPLATES: 'Templates',
            SETTINGS: 'Settings',
            SHARE_FORM: 'Share Form',
            SAVE: 'Save',
            FORM_ELEMENTS: 'Form Elements',
            HEADER: 'Header',
            DIVIDER: 'Divider (Form Line)',
            FIELD_SETTINGS: 'Field Settings',
            FIELD: 'Field',
            TITLE: 'Title',
            LABEL: 'Label',
            DESCRIPTION: 'Description',
            HELP_TEXT: 'Help Text',
            REQUIRE: 'Required field',
            ENTER_DESCRIPTION: 'Enter a description...',
            DEFAULT_VALUE: 'Default Value',
            AVAILABLE_TEMPLATES: 'Available Templates',
            SELECTED_TAMPLATES: 'Selected Templates',
            FORM_STATUS: 'Form Status',
            SHARE: 'Share',
            COPY_LINK: 'Copy Link',
            LINK_COPIED: 'Link Copied',
            CODE_COPIED: 'Code Copied',
            PREVIEW: 'Preview',
            SEARCH_TEMPLATE_PLACEHOLDER: 'Search in available templates...',
            CUSTOM_FORMS_TITLE: 'Custom Forms',
            CUSTOM_FORMS_DESCRIPTION: 'Create a custom form, associate with templates. Automatically generate your documents...',
            CREATE_FORM: 'Create Form',
            FORM_PASSIVE_MESSAGE: 'This form is not currently available.',
            SUBMIT: 'Submit',
            RESET: 'Reset',
            ARE_YOU_SURE_DESCRIPTION: 'You are permanently deleting the form. Are you sure you want to delete?',
            OPEN_FORM: 'Open Form',
            PROVIDE_FORM_TITLE: 'Please provide a form title',
            CREATING_DOCUMENT: 'Creating Document',
            INVALID_EMAIL: 'Please provide a valid email address',
            SEND_EMAIL: 'Send Email',
            RECEIVERS: 'Receivers',
            MESSAGE_TEXT: 'Message Text',
            INVALID_MESSAGE: 'Please provide a message',
            CONFIRM_AND_SEND: 'Send',
        }
    },
    MONTHS: {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December'
    },
    DASHBOARD: {
        DATE_TYPES: {
            startDate: 'Start Date',
            endDate: 'End Date',
            effectiveDate: 'Effective Date',
            renewalDate: 'Renewal Date',
            reminderDate: 'Reminder',
        },
        CHARTS: {
            NUMBER_OF_CONTRACTS_ADDED: 'Number of Contracts Added',
        }
    },
    CUSTOM_FIELDS: {
        TOUSHANDS_SEPARATOR: 'Thousands separator is dot (.) and decimal is comma (,).'
    },
}