import React, { useEffect, useState } from "react";

import { Card, ListGroup, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import FeatherIcons from "feather-icons-react";
import { getListCustomFields } from "@redux/contract/index.actions";
import { toggleGenericAddCustomFieldModal } from "@redux/components/index.actions";

import { connect } from "react-redux";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts
});
const mapActionsToProps = {
  getListCustomFields,
  toggleGenericAddCustomFieldModal
};

const EditingFieldList: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();


  return (
    <div className="d-flex gap-1 align-items-center justify-content-between field-disabled">
      <span>
        {props?.item?.title}
      </span>
      <Button
        className="btn btn-sm btn-link text-info outline-0 border-0 m-0 p-1">
        <i className="icon">
          <span>
            <FeatherIcons icon="lock"></FeatherIcons>
          </span>
        </i>
      </Button>

    </div >
  );
};

export default connect(mapStateToProps, mapActionsToProps)(EditingFieldList);
