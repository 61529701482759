import React, { useEffect, useState } from "react";

import { Card, ListGroup, OverlayTrigger, Tooltip, Button, Modal } from "react-bootstrap";
import FeatherIcons from "feather-icons-react";
import { getListCustomFields, addCustomFieldToContractType } from "@redux/contract/index.actions";
import { addCustomFieldsToCustomForm } from "@redux/custom-forms/index.actions";
import { toggleGenericAddCustomFieldModal } from "@redux/components/index.actions";

import { connect } from "react-redux";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import apiInstance from "@clients/api.client";
import { BiText, BiTime } from "react-icons/bi";
import { BsCalendar2Date, BsTextareaT } from "react-icons/bs";
import { TiSortNumerically } from "react-icons/ti";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { GrSelection } from "react-icons/gr";
import CreateCustomFieldsModal from "./CreateCustomFieldsModal";

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts,
  companies: state.companies,
  customForms: state.customForms,
});
const mapActionsToProps = {
  getListCustomFields,
  toggleGenericAddCustomFieldModal,
  addCustomFieldToContractType,
  addCustomFieldsToCustomForm
};

const EditingFieldList: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const handleAddCustomFieldToForm = () => {
    console.log(props?.item);

    let requestData = {
      formId: props?.customForms?.selectedForm?._id,
      title: props?.item?.title,
      type: 'CustomFields',
      isRequired: props?.item?.isOptional || false,
      field: props?.item?._id,

    }

    props?.addCustomFieldsToCustomForm(requestData).then((res: any) => {
      console.log(res);
    });


  }


  const fieldOptions: {
    key: string;
    value?: string;
    label: string;
    optionValues?: boolean;
    icon?: any
  }[] = [
      {
        key: "Text",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.TEXT"),
        optionValues: false,
        icon: () => <BiText />
      },
      {
        key: "TextArea",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.TEXTAREA"),
        optionValues: false,
        icon: () => <BsTextareaT />
      },
      {
        key: "Number",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.NUMBER"),
        optionValues: false,
        icon: () => <TiSortNumerically />
      },
      {
        key: "Email",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.EMAIL"),
        optionValues: false,
        icon: () => <MdOutlineAlternateEmail />
      },
      {
        key: "Date",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.DATE"),
        optionValues: false,
        icon: () => <BsCalendar2Date />
      },
      {
        key: "Time",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.TIME"),
        optionValues: false,
        icon: () => <BiTime />
      },
      {
        key: "SingleSelect",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.SINGLE_SELECTION"),
        optionValues: true,
        icon: () => <GrSelection />
      },
    ]


  const handleAddNewCustomField = (field: any) => {
    setField(field)
    toggleModal()
  };


  const [field, setField] = useState('Text');
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  return (

    <>
      <div className="cf-boxes-container" style={{
        flexWrap: 'wrap'
      }}>
        {
          fieldOptions.map((item, index) => {
            return (


              <span className="cf-box" onClick={e => handleAddNewCustomField(item?.key)}>
                <i className="icon">
                  <span>
                    {item.icon()}
                  </span>
                </i>
                <p title={props?.item?.title} className="text-center">{item?.label}</p>
              </span>




            )
          })
        }
      </div >
      <CreateCustomFieldsModal
        modal={modal}
        toggleModal={toggleModal}
        field={field}
      ></CreateCustomFieldsModal>
    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(EditingFieldList);
