import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { connect } from "react-redux";
import {
  createContractCompany,
} from "@redux/contract/index.actions";

import FormInput from "@components/FormInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts,
  companies: state.companies,
});

const mapActionsToProps = {
  createContractCompany,
};

const StatusAdd = (props: any) => {
  const { t } = useTranslation();

  const [partiesType, setPartiesType] = useState<"legalEntity" | "naturalEntity">('legalEntity');

  const partiesTypes = {
    naturalEntity: {
      title: 'Gerçek Kişi'
    },
    legalEntity: {
      title: 'Tüzel Kişi'
    }
  }

  const partiesSides = {
    internal: {
      title: 'Şirket Tarafı (Dahili)'
    },
    external: {
      title: 'Karşı Taraf'
    }
  }

  const [partiesSide, setPartiesSide] = useState<"internal" | "external">('internal');


  const [data, setData] = useState<{
    title: string
    description?: string
    tags?: string
    taxID?: string
    tcID?: string
    taxOffice?: string
    address?: string
    contactName?: string
    email?: string
  }>({
    title: '',
    description: '',
    tags: '',
  });

  const handleInput = (target: any) => {

    setData({ ...data, ...{ [target?.name]: target?.value } })
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const tags: any[] = data?.tags ? data?.tags.split(',') : [];

    const request: {
      title: string
      partiesType?: string
      partiesSide?: string
      description?: string
      company: string
      tags?: any[]
      taxID?: string
      tcID?: string
      taxOffice?: string
      address?: string
      contactName?: string
      email?: string
    } = {
      title: data?.title,
      taxID: data?.taxID,
      tcID: data?.tcID,
      taxOffice: data?.taxOffice,
      address: data?.address,
      contactName: data?.contactName,
      email: data?.email,
      partiesType: partiesType,
      partiesSide: partiesSide,
      description: data?.description,
      company: props?.companies?.currentCompany,
      tags: tags,
    }
    console.log(request)
    if (!request?.title) {
      toast.error(t("CONTRACTS_COMPANIES.NO_TITLE"))
      return;
    }
    if (!request?.company) {
      toast.error(t("CONTRACTS_COMPANIES.NO_COMPANY"))
      return;
    }
    props?.createContractCompany(request).then(() => {
      props?.modalClose()
    })


  }

  return (
    <form onSubmit={event => handleSubmit(event)} className="px-2">
      <Row>
        <Col md={12}>
          <div>
            <Form.Group className="mb-2">
              <Form.Label>
                {t("Kayıt Tipi")}
              </Form.Label>

              <Select
                key={'partiesType'}
                isMulti={false}
                name={'partiesType'}
                value={
                  {
                    label: partiesTypes[partiesType]?.title,
                    value: partiesType,
                    name: 'partiesType'
                  }
                }
                options={[
                  {
                    label: 'Gerçek Kişi',
                    value: 'naturalEntity',
                    name: 'partiesType'
                  },
                  {
                    label: 'Tüzel Kişi',
                    value: 'legalEntity',
                    name: 'partiesType'
                  }
                ]}
                className="react-select react-select-container"
                classNamePrefix="react-select"
                onChange={(e: any) => {
                  console.log(e)
                  setPartiesType(e?.value)
                }}
              ></Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                {t("Taraf seçimi yapınız")}
              </Form.Label>

              <Select
                key={'partiesType'}
                isMulti={false}
                name={'partiesType'}
                value={
                  {
                    label: partiesSides[partiesSide]?.title,
                    value: partiesSide,
                    name: 'partiesSide'
                  }
                }
                options={[
                  {
                    label: partiesSides['internal']?.title,
                    value: 'internal',
                    name: 'partiesSide'
                  },
                  {
                    label: partiesSides['external']?.title,
                    value: 'external',
                    name: 'partiesSide'
                  }
                ]}
                className="react-select react-select-container"
                classNamePrefix="react-select"
                onChange={(e: any) => {
                  console.log(e)
                  setPartiesSide(e?.value)
                }}
              ></Select>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>
                {partiesType === 'legalEntity' ? t("CONTRACTS_COMPANIES.TITLE_LABEL_COMPANY") : t("CONTRACTS_COMPANIES.TITLE_LABEL_CONTACT")}
              </Form.Label>
              <FormInput
                name="title"
                type="text"
                onInput={(event) => handleInput(event?.target)}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>
                {partiesType === 'legalEntity' ? t("CONTRACTS_COMPANIES.TAX_ID") : t("CONTRACTS_COMPANIES.TC_ID")}
              </Form.Label>
              <FormInput
                name={partiesType === 'legalEntity' ? 'taxID' : 'tcID'}
                type="text"
                onInput={(event) => handleInput(event?.target)}
              />
            </Form.Group>

            {partiesType === 'legalEntity' ? (
              <Form.Group className="mb-2">
                <Form.Label>
                  {t("CONTRACTS_COMPANIES.TAX_OFFICE")}
                </Form.Label>
                <FormInput
                  name="taxOffice"
                  type="text"
                  onInput={(event) => handleInput(event?.target)}
                />
              </Form.Group>
            ) : null}

            <Form.Group className="mb-2">
              <Form.Label>
                {partiesType === 'legalEntity' ? t("CONTRACTS_COMPANIES.COMPANY_ADDRESS") : t("CONTRACTS_COMPANIES.ADDRESS")}
              </Form.Label>
              <FormInput
                name="address"
                type="text"
                onInput={(event) => handleInput(event?.target)}
              />
            </Form.Group>

            {partiesType === 'legalEntity' ? (
              <Form.Group className="mb-2">
                <Form.Label>
                  {t("CONTRACTS_COMPANIES.CONTACT_NAME")}
                </Form.Label>
                <FormInput
                  name="contactName"
                  type="text"
                  onInput={(event) => handleInput(event?.target)}
                />
              </Form.Group>
            ) : null}


            <Form.Group className="mb-2">
              <Form.Label>
                {partiesType === 'legalEntity' ? t("CONTRACTS_COMPANIES.CONTACT_EMAIL") : t("CONTRACTS_COMPANIES.EMAIL")}
              </Form.Label>
              <FormInput
                name="email"
                type="email"
                onInput={(event) => handleInput(event?.target)}
              />
            </Form.Group>


          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="text-end mt-3">
            <Button
              variant="secondary"
              className="me-1"
              onClick={props?.modalClose}
            >
              {t("CONTRACTS_COMPANIES.CANCEL")}
            </Button>
            <Button variant="success" type="submit">
              {t("CONTRACTS_COMPANIES.CREATE")}

            </Button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(StatusAdd);
