import React, { useEffect, useState } from 'react';
import { Row, Col, Dropdown, Table, ButtonGroup, Pagination, Button } from 'react-bootstrap';

//TYPES
import { AllRecentFilesProps } from "@pages/apps/FileManager/interfaces/AllRecentFiles.interfaces";
import { BsArrowDown, BsSortAlphaDown, BsSortAlphaUp } from 'react-icons/bs';
import FeatherIcons from 'feather-icons-react';
import './index.scss';
import moment from 'moment';
import ContractsSelect from '@components/SelectsPaginated/Contracts';
import api from '@clients/api.client';
import { connect } from 'react-redux';
import { DefaultLoader } from '@components/Loader';
import { toggleFilesVersionModal, toggleGoogleFilePermissionModal } from '@redux/components/index.actions';
import { toggleGenericGoogleDocsIframeModal } from '@redux/components/index.actions';
import { updateFile, deleteFile, getFileVersions, setFileData, getExportLinks, setFileToGenericPermissionModal } from '@redux/files/index.actions';
import _ from 'lodash';
import AcceptOrClosePopup from '@popups/AcceptOrClosePopup';
import drive from '@integration/GDrive/client/drive.client';
import { useTranslation } from 'react-i18next';
import cdnServicesInstance from '@clients/cdnservices.client';
import customCDNServicesInstance from '@clients/customcdn.client';
import { FileIcon, defaultStyles, DefaultExtensionType } from 'react-file-icon';


const mapStateToProps = (state: any) => ({
    companies: state?.companies,
    files: state?.files,
    contracts: state?.contracts,
    users: state?.users
})

const mapActionsTopProps = {
    updateFile,
    deleteFile,
    toggleFilesVersionModal: toggleFilesVersionModal,
    getFileVersions: getFileVersions,
    toggleGenericGoogleDocsIframeModal: toggleGenericGoogleDocsIframeModal,
    setFileData: setFileData,
    getExportLinks: getExportLinks,
    toggleGoogleFilePermissionModal: toggleGoogleFilePermissionModal,
    setFileToGenericPermissionModal: setFileToGenericPermissionModal
}

const trimEllip = (str: string, length: number) => {
    return str.length > length ? str.substring(0, length) + "..." : str;
}


const AllRecentFiles: React.FunctionComponent<any> = (props) => {
    const [sort, setSort] = useState<any>({
        filename: null,
        createdAt: null
    });
    const { t } = useTranslation();
    const [totalPage, setTotalPage] = useState<number>(0);

    const [showAcceptOrCloseModal, setShowAcceptOrCloseModal] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [showAllTags, setShowAllTags] = useState<boolean[]>([]);
    const [editMode, setEditMode] = useState<boolean[]>([]);
    const [editedData, setEditedData] = useState<any[]>([]);
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState<number | null>(null);
    useEffect(() => {
        if (props?.data) {
            setData(props?.data);
            setShowAllTags(Array(props?.data.length).fill(false));
            setEditMode(Array(props?.data.length).fill(false));
            setEditedData(Array(props?.data.length).fill({}));
        }
    }, [props?.data])

    const toggleEditMode = (index: number) => {
        let newEditMode = [...editMode];
        newEditMode[index] = !newEditMode[index];
        setEditMode(newEditMode);
    }

    const openDeleteModal = (index: number) => {
        if (!showAcceptOrCloseModal) {
            setCurrentDeleteIndex(index);
            setShowAcceptOrCloseModal(true);
        }
    }

    const deleteFile = async (index: number) => {
        const response: any = await props?.deleteFile({
            _id: data?.[index]?._id
        })

        const responseData: any = response;
        setData(data.filter((item: any, i: number) => {
            if (i === index) {
                return false
            }
            return true;
        }))
        setEditedData(Array(data.length).fill({}));
    }

    const editFile = async (file: any, index: number, edit: {
        filename?: string | null,
        tags?: string[] | null
    }) => {
        const response: any = props?.updateFile({
            _id: file?._id,
            filename: edit?.filename || null,
            tags: edit?.tags || null
        })

        const responseData: any = response;
        setData(data.map((item: any, i: number) => {
            if (i === index) {
                return {
                    ...item,
                    ...edit
                }
            }
            return item;
        }))
        setEditedData(Array(data.length).fill({}));
        toggleEditMode(index);
    }

    const renderPaginationItems = (): JSX.Element => {
        return (
            <div className="absolute-paginate">
                <Dropdown flip key={2} as={ButtonGroup}>
                    <Dropdown.Toggle
                        variant={"primary"}
                        style={{
                            backgroundColor: '#fff',
                            color: "black",
                            width: "80px",
                            borderWidth: "1px",
                            borderColor: "#e2e7f1",
                            margin: "0px 2px 0px 2px",
                        }}
                        className="cursor-pointer">
                        1
                        <i className="icon">
                            <span>
                                <FeatherIcons icon="chevron-down"></FeatherIcons>
                            </span>
                        </i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {Array(10).fill(0).map((batch: number, index: number) => (
                            <Dropdown.Item onClick={(e: any) => {
                            }} disabled={index + 1 === 1} active={index + 1 === 1}>
                                {index + 1}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }

    const toggleAllTags = (index: number) => {
        const newShowAllTags = [...showAllTags];
        newShowAllTags[index] = !newShowAllTags[index];
        setShowAllTags(newShowAllTags);
    }

    const handleSort = (key: string) => {
        const sortValue = (!sort?.[key])
            ? -1
            : sort?.[key] === -1
                ? 1
                : null;
        props?.onSortChange({
            [key]: sortValue
        })
        setSort({
            [key]: sortValue
        })
    }

    const generateFileUrl = (file: any) => {
        if (file?.source?.sourceKey === "amazon-s3") {
            return cdnServicesInstance.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "custom-cdn") {
            return file?.fileUrl + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "google-drive") {
            return file?.fileUrl;
        } else if (file?.source?.sourceKey === "one-drive") {
            return file?.fileUrl;
        } else if (file?.source?.sourceKey === "share-point") {
            //return file?.fileUrl;
            //return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
            return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file.key}/url` + "?accessKey=" + props?.users?.authorization?.token;
        } else {
            return ""
        }
    }

    const generateDownloadUrl = (file: any) => {
        if (file?.source?.sourceKey === "amazon-s3") {
            return cdnServicesInstance.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "custom-cdn") {
            return props?.companies?.company?.customCDNEndpoint + "/local/download/" + file?.key + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "google-drive") {
            return cdnServicesInstance.defaults.baseURL + `/gdrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "one-drive") {
            return cdnServicesInstance.defaults.baseURL + `/onedrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "share-point") {
            return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else {
            return ""
        }
    }

    useEffect(() => {
        const totalPage = Math.ceil(props?.pagination?.totalCount / props?.pagination?.batchSize);
        //console.log(totalPage);
        setTotalPage(totalPage);
    }, [props?.pagination])


    if (props?.contracts?.endedFetchingFiles && data?.length == 0) {
        return (<></>)
    }


    return (
        <div className="table-responsive">

            <Table className="table table-centered table-stripped overview-files-table">
                <thead>
                    <tr>
                        <th className={`border-0 sortable cursor-pointer ${sort?.filename && "sorted"}`} onClick={(e: any) => {
                            handleSort("filename")
                        }}>
                            {t("CONTRACTS.OVERVIEW.FILES.FILENAME")} {sort?.filename && (sort?.filename === -1 ? <BsSortAlphaUp /> : <BsSortAlphaDown />)}
                        </th>
                        {/* <th className={`border-0 sortable cursor-pointer ${sort?.createdAt && "sorted"}`} onClick={(e: any) => {
                            handleSort("createdAt")
                        }}>
                            Created Date {sort?.createdAt && (sort?.createdAt === -1 ? <BsSortAlphaUp /> : <BsSortAlphaDown />)}
                        </th> */}
                        <th className="border-0">{t("CONTRACTS.OVERVIEW.FILES.UPLOADED_BY")}</th>
                        {/* <th className="border-0">Size</th>
                        <th className="border-0">Type</th>
                        <th className="border-0">Source</th> */}
                        <th className="border-0">{t("CONTRACTS.OVERVIEW.FILES.ACTIONS")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (!props?.contracts?.endedFetchingFiles && data?.length == 0) && (<tr>
                            <td colSpan={9}>
                                <DefaultLoader />
                            </td>
                        </tr>)
                    }
                    {
                        (props?.contracts?.endedFetchingFiles && data?.length == 0) && (<tr className="no-found-data">
                            <td colSpan={9}>
                                {t("CONTRACTS.OVERVIEW.FILES.NO_FILES")}
                            </td>
                        </tr>)
                    }
                    {(data || []).map((file: any, index: number) => {
                        return (
                            <tr key={index}>
                                <td className="filename">
                                    {editMode[index] ?
                                        <div className="d-flex align-items-center justify-content-center">
                                            <input onChange={(e) => {
                                                const newEditedData = [...editedData];
                                                newEditedData[index] = {
                                                    ...newEditedData[index],
                                                    filename: e.target.value
                                                }
                                                setEditedData(newEditedData);
                                            }} type="text" className="input-group-text" defaultValue={file?.filename} />
                                        </div>
                                        : <a target="__blank" href={generateFileUrl(file)}>{file?.filename}</a>
                                    }
                                </td>
                                {/* <td className="createdDate">{moment(file?.createdAt).format('LLLL')}</td> */}
                                <td className="user">{file?.fullName}</td>

                                {/* <td className="size">{file?.fileSize}</td>
                                <td className="type">{file?.type?.title}</td>
                                <td className="source-logo">
                                    <img src={file?.source?.logo} alt="file" width="35px" height="35px" />
                                </td> */}
                                <td className="action">
                                    {editMode[index]
                                        ? <Button onClick={(e: any) => {
                                            if (Object.keys(editedData[index]).length > 0) {
                                                editFile(file, index, {
                                                    ...editedData[index]
                                                })
                                            } else {
                                                editMode[index] = false;
                                                setEditedData(editMode);
                                            }
                                        }} style={{ marginLeft: 6 }}>
                                            Save Changes
                                        </Button>
                                        : <Dropdown>
                                            <Dropdown.Toggle
                                                as="a"
                                                style={{
                                                    border: "1px solid black"
                                                }}
                                                className="cursor-pointer table-action-btn btn btn-light btn-xs">
                                                <i className="uil uil-ellipsis-h" style={{
                                                    fontSize: "0.8rem"
                                                }}></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu align="end">
                                                <Dropdown.Item onClick={(e: any) => {
                                                    toggleEditMode(index)
                                                }}>
                                                    <i className="uil uil-pen me-2 text-muted vertical-middle"></i>
                                                    {editMode[index]
                                                        ? t("FILES.CLOSE_EDIT_MODE")
                                                        : t("FILES.EDIT")
                                                    }
                                                </Dropdown.Item>
                                                {file?.fileExtra?.importFrom == 'template' && file?.source?.sourceKey === "google-drive"
                                                    ? (
                                                        <Dropdown.Item onClick={(e: any) => {
                                                            props?.getExportLinks({ _id: file?._id })
                                                            //toggleShowExportModal();
                                                        }}>
                                                            <i className="uil uil-arrow-to-bottom me-2 text-muted vertical-middle"></i>
                                                            {t("FILES.EXPORT")}
                                                        </Dropdown.Item>
                                                    )
                                                    : (
                                                        <Dropdown.Item onClick={(e: any) => {
                                                            window.open(generateDownloadUrl(file), "__blank");
                                                            //window.open(`https://drive.google.com/uc?id=${file?.key}&export=download`, "__blank")
                                                        }}>
                                                            <i className="uil uil-arrow-to-bottom me-2 text-muted vertical-middle"></i>
                                                            {t("FILES.DOWNLOAD")}
                                                        </Dropdown.Item>
                                                    )}
                                                {file?.source?.sourceKey === "google-drive"
                                                    ? (
                                                        <Dropdown.Item onClick={(e: any) => {
                                                            props?.setFileToGenericPermissionModal({ _id: file?._id, file: file })
                                                        }}>
                                                            <i className="uil uil-lock me-2 text-muted vertical-middle"></i>
                                                            {'İzinler'}
                                                        </Dropdown.Item>
                                                    ) : null}


                                                <Dropdown.Item onClick={(e: any) => {
                                                    openDeleteModal(index);
                                                }}>
                                                    <i className="uil uil-trash-alt me-2 text-muted vertical-middle"></i>
                                                    {t("FILES.DELETE")}
                                                </Dropdown.Item>


                                                {file?.source?.sourceKey === "google-drive" && ((file: any) => {

                                                    /* Create an array includes excel file extentions */
                                                    const excelExtention = [
                                                        "xls",
                                                        "xlsx",
                                                        "APPLICATION/VND.GOOGLE-APPS.SPREADSHEET",
                                                        "APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET",
                                                        "APPLICATION/VND.MS-EXCEL",
                                                    ];

                                                    /* Create an array includes document file extentions */
                                                    const documentExtention = [
                                                        "doc",
                                                        "docx",
                                                        "APPLICATION/VND.GOOGLE-APPS.DOCUMENT",
                                                        "APPLICATION/MSWORD",
                                                        "APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT",
                                                        "APPLICATION/VND.GOOGLE-APPS.KIX",
                                                    ]

                                                    /* Create an array includes slayt file extentions */
                                                    const presentationExtention = [
                                                        "ppt",
                                                        "pptx",
                                                        "APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.PRESENTATIONML.PRESENTATION",
                                                        "APPLICATION/VND.GOOGLE-APPS.PRESENTATION",
                                                        "APPLICATION/VND.MS-POWERPOINT",
                                                    ];

                                                    const fileExtension = file?.filename?.split(".")?.[file?.filename?.split(".")?.length - 1];


                                                    /*
                                                                                                        if (file?.fileExtra?.integration === 'google-docs') {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <Dropdown.Item onClick={(e: any) => {
                                                                                                                        props?.setFileData({ file, documentTypeForUrl: file?.fileExtra?.documentType });
                                                                                                                        props?.toggleGenericGoogleDocsIframeModal();
                                                                                                                    }}
                                                                                                                        className="d-flex align-items-center justify-content-left gap-1">
                                                                                                                        <i style={{ width: '20px' }}><FileIcon labelUppercase={true} extension={fileExtension} /></i>
                                                                                                                        Edit in Google Docs
                                                                                                                    </Dropdown.Item>
                                                                                                                    <Dropdown.Item onClick={(e: any) => {
                                                                                                                        alert('Export file key: ' + file?.key)
                                                                                                                    }}
                                                                                                                        className="d-flex align-items-center justify-content-left gap-1">
                                                                                                                        <i style={{ width: '20px' }}><FileIcon labelUppercase={true} extension={fileExtension} /></i>
                                                                                                                        Export
                                                                                                                    </Dropdown.Item>
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                        
                                                                                                        */


                                                    if (documentExtention.includes(file?.type?.title)) {
                                                        return (
                                                            <Dropdown.Item onClick={(e: any) => {
                                                                props?.setFileData({ file, documentTypeForUrl: 'document' });
                                                                props?.toggleGenericGoogleDocsIframeModal();
                                                            }}
                                                                className="d-flex align-items-center justify-content-left gap-1">
                                                                <i style={{ width: '20px' }}><FileIcon labelUppercase={true} extension={fileExtension} /></i>
                                                                Document
                                                            </Dropdown.Item>
                                                        )
                                                    }
                                                    else if (presentationExtention.includes(file?.type?.title)) {
                                                        return (
                                                            <Dropdown.Item onClick={(e: any) => {
                                                                props?.setFileData({ file, documentTypeForUrl: 'presentation' });
                                                                props?.toggleGenericGoogleDocsIframeModal();
                                                            }}
                                                                className="d-flex align-items-center justify-content-left gap-1">
                                                                <i style={{ width: '20px' }}><FileIcon labelUppercase={true} extension={fileExtension} /></i>
                                                                Presentation
                                                            </Dropdown.Item>
                                                        )
                                                    }

                                                    else if (excelExtention.includes(file?.type?.title)) {
                                                        return (
                                                            <>

                                                                <Dropdown.Item onClick={(e: any) => {
                                                                    props?.setFileData({ file, documentTypeForUrl: 'spreadsheets' });
                                                                    props?.toggleGenericGoogleDocsIframeModal();
                                                                }}
                                                                    className="d-flex align-items-center justify-content-left gap-1">
                                                                    <i style={{ width: '20px' }}><FileIcon labelUppercase={true} extension={fileExtension} /></i>
                                                                    Edit on Spreadsheets
                                                                </Dropdown.Item>

                                                            </>
                                                        )
                                                    } else {
                                                        return null
                                                    }



                                                })(file)}


                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={12}>

                            <div className="tfoot-container">
                                <div className="tfoot-item justify-content-start d-flex align-items-center">
                                    <div className="d-flex align-items-start justify-content-center">
                                        <input
                                            type="number"
                                            max={totalPage}
                                            min={1}
                                            disabled={totalPage === 1}
                                            className="input-group-text page-input"
                                            defaultValue={1}
                                        />
                                        <Button onClick={(e: any) => {
                                            if (e.target.previousSibling) {
                                                props?.onPageChange(e.target?.previousSibling?.value)
                                            }
                                        }} style={{ marginLeft: 6, width: '120px' }}>
                                            {t("CONTRACTS.OVERVIEW.FILES.GO_TO_PAGE")}
                                        </Button>
                                    </div>
                                </div>
                                <div className="tfoot-item justify-content-end d-flex align-items-center bold w-100" style={{
                                    fontWeight: "bold"
                                }}>
                                    {t("CONTRACTS.OVERVIEW.FILES.TOTAL_FILES")} {props?.pagination?.totalCount} /
                                    {t("CONTRACTS.OVERVIEW.FILES.MAX_PAGE")} {totalPage}
                                </div>

                            </div>
                        </td>
                    </tr>
                </tfoot>
            </Table>
            <AcceptOrClosePopup
                show={showAcceptOrCloseModal}
                onAccept={() => {
                    if (currentDeleteIndex !== null) {
                        deleteFile(currentDeleteIndex)
                    }
                }}
                renderHeader={(<h4>{t("CONTRACTS.OVERVIEW.FILES.DELETE_FILE")}</h4>)}
                onClose={() => {
                    setShowAcceptOrCloseModal(false);
                    setCurrentDeleteIndex(null);
                }}>
                <div>{t("CONTRACTS.OVERVIEW.FILES.DELETE_FILE_DESCRIPTION")}</div>
            </AcceptOrClosePopup>
        </div>
    );
};

export default connect(mapStateToProps, mapActionsTopProps)(AllRecentFiles);
