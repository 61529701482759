import { Card, Row, Col } from "react-bootstrap";

import DefaultFieldsListItem from "@pages/contractTypes/components/CustomFieldOffcanvas/DefaultFieldsListItem";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({});
const mapActionsToProps = {};

const DefaultFieldsList: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <h6 className="m-0 p-0 mb-2">Default Fields</h6>
        <Row className="row">
          {([
            {
              _id: 1,
              title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.TITLE")
            },
            { _id: 2, title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.STATUS") },
            { _id: 2, title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.PIPELINE") },
            { _id: 3, title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.USER") },
            { _id: 4, title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.CREATED_DATE") },
            { _id: 5, title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.UPDATED_DATE") },
            { _id: 6, title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.EFFECTIVE_DATE") },
            { _id: 7, title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.RENEWAL_DATE") }
          ]).map((item: any, index: number) => (
            <Col
              key={index}
              as="div"
              className="col-md-5 rounded m-1 border border-1"
            >
              <DefaultFieldsListItem
                item={item}
              />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(DefaultFieldsList);
