import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Row, Col, Form, Button, Alert, Navbar, Nav, Card, Tab, ListGroup, FloatingLabel, Offcanvas, Dropdown } from "react-bootstrap";


import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { TagsInput } from "react-tag-input-component";
import { connect } from "react-redux";
import { deleteCustomField, setNewContractTypeData, createNewContractType } from "@redux/contract/index.actions";
import { toggleGenericAddCustomFieldModal, toggleGenericEditCustomFormsModal } from "@redux/components/index.actions";
import { removeFieldFromForm, reOrderFieldsInCustomForm, editFormFieldReducer, editFormField, removeTemplateFromCustomForms, editForm, refreshTemplateList } from "@redux/custom-forms/index.actions";
import {
  setTemplateDataInCustomForms,
  createTemplate
} from "@redux/files/index.actions";
import CustomFormFieldArea, { TCustomComponent } from "@components/CustomFormFieldArea";
// components
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "./index.scss";
import EditingFieldList from "@popups/EditCustomFormsPopup/components/EditingFieldList";
import EditingDropList from "@popups/EditCustomFormsPopup/components/EditingDropList";
import TemplateManagementList from "@popups/EditCustomFormsPopup/components/TemplateManagementList";
import TemplateList from "@popups/EditCustomFormsPopup/components/TemplateList";
import FormElementsListItem from "./components/FormElementsListItem";
import FeatherIcons from "feather-icons-react";
import { toast } from "react-toastify";
import { SiMicrosoftexcel, SiMicrosoftpowerpoint, SiMicrosoftword } from "react-icons/si";
import { FiFilePlus, FiSave } from "react-icons/fi";
import Spinner from "@components/Spinner";
import { MdDataSaverOff } from "react-icons/md";
import { trim } from "lodash";
import apiInstance from "@clients/api.client";



const mapStateToProps = (state: any) => ({
  companies: state.companies,
  contracts: state.contracts,
  components: state.components,
  customForms: state.customForms
});
const mapActionsToProps = {
  createNewContractType,
  setNewContractTypeData,
  deleteCustomField,
  toggleGenericAddCustomFieldModal,
  toggleGenericEditCustomFormsModal,
  removeFieldFromForm,
  reOrderFieldsInCustomForm,
  editFormFieldReducer,
  editFormField,
  removeTemplateFromCustomForms,
  editForm,
  setTemplateDataInCustomForms,
  createTemplate,
  refreshTemplateList
};

const CreateContractType = (props: any) => {
  const { t } = useTranslation();



  const [selected, setSelected] = useState<string[]>([]);
  const [shareModal, setShareModal] = useState(false);

  const toggleShareModal = () => {
    setShareModal((prevstate) => !prevstate);
  };


  const [sendEmailModal, setSendEmailModal] = useState(false);

  const toggleSendEmailModal = () => {
    setSendEmailModal((prevstate) => !prevstate);
  };

  const [mailto, setMailto] = useState("");
  const [loading, setLoading] = useState(false);

  const onDragEnd = (result: DropResult) => {

    const { source, destination } = result;

    if (source.droppableId === 'fieldList' && destination?.droppableId == 'contractCustomFields') {

      props.setNewContractTypeData({
        key: "addCustomField",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

    if (source.droppableId === 'contractCustomFields' && destination?.droppableId == 'fieldList') {

      props.setNewContractTypeData({
        key: "removeCustomField",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

    if (source.droppableId === 'contractCustomFields' && destination?.droppableId == 'contractCustomFields') {
      const data = {
        sourceIndex: source.index,
        destinationIndex: destination.index
      }

      props?.reOrderFieldsInCustomForm(data)
    }

  };

  /* Tabs */
  const [activeTab, setactiveTab] = useState<string>("forms");
  const handleSelect = (eventKey: string | null) => {
    setactiveTab(eventKey!);
  };

  const handleFieldEdit = (event: any) => {

    if (event?.target?.name === 'isRequired') {
      props?.editFormField({
        targetName: event?.target?.name,
        targetValue: event?.target?.checked,
      })
    } else {
      props?.editFormField({
        targetName: event?.target?.name,
        targetValue: event?.target?.value,
      })
    }
  }

  const handleSaveStatus = (event: any) => {
    props.editForm({
      [event?.name]: event?.value,
    })
  }

  const formStatuses: any = {
    false: {
      title: t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.FORM_STATUS_PASSIVE')
    },
    true: {
      title: t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.FORM_STATUS_ACTIVE')
    }
  }

  const handleRemoveTemplateFromCustomForm = (template: any, index: number) => {

    props?.removeTemplateFromCustomForms({ ...template, index });
  }

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (props?.customForms?.saving === true) {
      setSaving(true);
      setTimeout(() => {
        setSaving(false);
      }, 1000)
    }
  }, [props?.customForms?.saving])


  const createSingleTemplate = (type: string) => {
    props.createTemplate({ type: type }).then((res: any) => {
      props?.refreshTemplateList()
    })
  }
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const [message, setMessage] = useState("Lütfen aşağıdaki formu doldurunuz.");

  const sendEmail = () => {
    console.log(selected, "selected")
    console.log(message, "message")
    let link = `${window.location.host.includes('localhost') ? 'http://' + window.location.host : 'https://' + window.location.host}/form/${props?.companies?.company?.uid}/${props?.customForms?.selectedForm?.uid}`;

    const companyId = props?.companies?.currentCompany;
    const formId = props?.customForms?.selectedForm?._id;

    apiInstance.post(`/custom-forms/form/send-email/${companyId}/${formId}`, {
      emails: selected,
      message: message,
      link: link
    }).then((res: any) => {
      toast.success("Email başarıyla gönderildi.");
      setSendEmailModal(false);
      setMessage("Lütfen aşağıdaki formu doldurunuz.");
      setSelected([]);
    }).catch((err: any) => {
      toast.error("Email gönderilemedi.");
    })

  }

  return (
    <>


      <Offcanvas show={props?.components?.editCustomFormsModal}
        onHide={props?.toggleGenericEditCustomFormsModal}>
        <Offcanvas.Header>
          <div className="modal-header-wrapper">
            <div className="modal-header-left">
              <h4 className="modal-title">{t("Özel Formlar")}</h4>
            </div>
            <div className="modal-header-center">
              <Navbar bg="">
                <Nav
                  key={'tab1'}
                  defaultActiveKey="forms"
                  as="ul"
                  variant="tabs"
                  justify
                  activeKey={activeTab}
                  onSelect={handleSelect}
                >


                  <Nav.Item as="li" key={'index2'}>
                    <Nav.Link eventKey="forms">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.FORM')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key={'index1'}>
                    <Nav.Link eventKey="templates">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.TEMPLATES')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key={'index3'}>
                    <Nav.Link eventKey="settings">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.SETTINGS')}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar>
            </div>
            <div className="modal-header-right">
              <a className="btn btn-sm btn-outline-primary" href={`/form/${props?.companies?.company?.uid}/${props?.customForms?.selectedForm?.uid}`} target={'_blank'} >{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.OPEN_FORM')}</a>
              <Button size="sm" onClick={toggleShareModal} variant="outline-primary">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.SHARE_FORM')}</Button>
              <Button
                size="sm"
                className="d-flex align-items-center justify-content-center gap-1"
                onClick={props?.toggleGenericEditCustomFormsModal} disabled={saving} variant={saving ? 'secondary' : 'secondary'} >
                {saving ? (<div className="rotate"><MdDataSaverOff /></div>) : <FiSave />}
                {saving ? 'Saving' : 'Saved'}</Button>
              <Button
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={props?.toggleGenericEditCustomFormsModal} variant="link">
                <FeatherIcons icon="x" size={16} />
              </Button>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tab.Content bsPrefix="tab-content  pt-1">
            <Tab.Pane active={activeTab === "forms"} eventKey="forms">

              <Row>

                <Col md={12} className="mb-3">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Row>
                      <Col className="cf-inner-left" md={2}>
                        <EditingFieldList contractTypeId={props?.data?._id} />
                        <Card>
                          <Card.Header>
                            <Card.Title

                              className="m-0 p-0" style={{ fontSize: '14px' }} as={'h6'}>{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.FORM_ELEMENTS')}</Card.Title>
                          </Card.Header>
                          <Card.Body className="p-1">
                            <div className="custom-forms-field-list">
                              <ListGroup as="ul">
                                {([
                                  {
                                    title: t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.HEADER'),
                                    CustomComponent: 'Header',
                                    type: 'FormElements',
                                    field: null,

                                  },
                                  {
                                    title: t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.DIVIDER'),
                                    CustomComponent: 'Divider',
                                    type: 'FormElements',
                                    field: null,

                                  },
                                ]).map((item: any, index: number) => {
                                  return (
                                    <ListGroup.Item
                                      key={item?._id}
                                      as="li"
                                      className="rounded mb-1 border-1"
                                    >
                                      <FormElementsListItem item={item} />
                                    </ListGroup.Item>
                                  )

                                })}
                              </ListGroup>

                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col className="cf-inner-center" md={props?.customForms?.editSelectedFormFieldData?.type ? 7 : 10}>
                        <div className="cf-innet-center-wrapper">
                          <EditingDropList title={props?.data?.title} contractTypeId={props?.data?._id} />
                        </div>
                      </Col>
                      {props?.customForms?.editSelectedFormFieldData?.type ? (
                        <Col className="cf-inner-right" md={3}>
                          <Card>
                            <Card.Header>
                              <h6 className="fs-14">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.FIELD_SETTINGS')}</h6>
                            </Card.Header>
                            <Card.Body className="p-1">
                              <h5 className="fw-normal mb-0">Seçilen özel alan:</h5>
                              <div className="d-flex align-items-center justify-content-between p-2 mb-2" style={{ border: '1px solid #ccc', padding: '2px 5px' }}>
                                <div>
                                  <h5 className="mb-0 fw-bolder">{props?.customForms?.editSelectedFormFieldData?.field?.title}</h5>
                                  <div className="fs-12">{props?.customForms?.editSelectedFormFieldData?.CustomComponent}</div>
                                </div>
                                <div>
                                  <Button variant="link"
                                    onClick={() => { props?.removeFieldFromForm() }}
                                  >
                                    <FeatherIcons icon="x" size={14}></FeatherIcons>
                                  </Button>
                                </div>
                              </div>
                              <div className="field-settings-edit-form">

                                <>
                                  <Form.Group className="mb-2">
                                    <h5 className="fw-normal mb-0">{props?.customForms?.editSelectedFormFieldData?.type === 'FormElements' ? t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.TITLE') : t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.LABEL')}</h5>
                                    <Form.Control
                                      type="text"
                                      key={props?.customForms?.editSelectedFormFieldData?._id}
                                      name="title"
                                      onChange={(e: any) => props?.editFormFieldReducer({
                                        targetName: e?.target?.name,
                                        targetValue: e?.target?.value,
                                      })}
                                      onBlur={(e: any) => handleFieldEdit(e)}
                                      defaultValue={props?.customForms?.editSelectedFormFieldData?.title}
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-2">
                                    <h5 className="fw-normal mb-0">{props?.customForms?.editSelectedFormFieldData?.type === 'FormElements' ? t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.DESCRIPTION') : t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.HELP_TEXT')}</h5>
                                    <Form.Control
                                      type="text"
                                      key={props?.customForms?.editSelectedFormFieldData?._id}
                                      name="description"
                                      onChange={(e: any) => props?.editFormFieldReducer({
                                        targetName: e?.target?.name,
                                        targetValue: e?.target?.value,
                                      })}
                                      onBlur={(e: any) => handleFieldEdit(e)}
                                      placeholder="Enter a description..."
                                      defaultValue={props?.customForms?.editSelectedFormFieldData?.description} />
                                  </Form.Group>
                                  {props?.customForms?.editSelectedFormFieldData?.type === 'CustomFields' ? (
                                    <>

                                      <Form.Group className="mb-2 d-flex align-items-center justify-content-between p-2" style={{ border: '1px solid #ced4da', borderRadius: '5px' }}>
                                        <h5 className="fw-normal m-0">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.REQUIRE')}</h5>

                                        <Form.Check
                                          type="checkbox"
                                          key={props?.customForms?.editSelectedFormFieldData?._id}
                                          name="isRequired"
                                          onChange={(e: any) => {
                                            props?.editFormFieldReducer({
                                              targetName: e?.target?.name,
                                              targetValue: e?.target?.checked,
                                            })
                                            handleFieldEdit(e)
                                          }}
                                          placeholder={t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.ENTER_DESCRIPTION')}
                                          checked={props?.customForms?.editSelectedFormFieldData?.isRequired} />
                                      </Form.Group>
                                    </>
                                  ) : null}




                                </>

                              </div>
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Header>
                              <h5 className="fw-normal mb-0">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.DEFAULT_VALUE')}</h5>
                            </Card.Header>
                            <Card.Body className="p-1">
                              <Form.Group className="mb-2">


                                {
                                  ((item: any) => {

                                    let costumComponent: TCustomComponent = item.CustomComponent
                                    if (item?.field?.existOptionValues === true) {
                                      return React.createElement(CustomFormFieldArea[costumComponent], {
                                        size: 12,
                                        key: item?._id,
                                        name: item?._id,
                                        isDisabled: false,
                                        label: item?.title,
                                        helpText: item?.description || '',
                                        value: item?.defaultValue,
                                        onChange: (event: any) => {
                                          props?.editFormField({
                                            targetName: "defaultValue",
                                            targetValue: event?.label,
                                          })
                                        },
                                        require: item?.field?.isOptional,
                                        data: item
                                      })
                                    } else {
                                      return React.createElement(CustomFormFieldArea[costumComponent], {
                                        size: 12,
                                        key: item?._id,
                                        name: item?._id,
                                        isDisabled: false,
                                        label: item?.title,
                                        value: item?.defaultValue,
                                        helpText: item?.description || '',
                                        onChange: (event: any) => {
                                          props?.editFormField({
                                            targetName: "defaultValue",
                                            targetValue: event?.target?.value,
                                          })
                                        },
                                        require: item?.field?.isOptional,
                                        data: item
                                      })
                                    }

                                  })(props?.customForms?.editSelectedFormFieldData)
                                }
                              </Form.Group>
                            </Card.Body>

                          </Card>
                        </Col>
                      ) : null}

                    </Row>
                  </DragDropContext>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane active={activeTab === "templates"} eventKey="templates">

              <Row>
                <Col md={6}>
                  <Card>
                    <Card.Header>
                      <Row>
                        <Col md={12} className={"d-flex align-items-center justify-content-between"}>
                          <h4 className="page-title">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.AVAILABLE_TEMPLATES')}</h4>
                          <Dropdown >
                            <Dropdown.Toggle disabled={props?.files?.creatingTemplate} as={Button} variant="primary" className="d-flex align-items-center justify-content-center" id="dropdown-basic">
                              {props?.files?.creatingTemplate ? (<>
                                <Spinner size="sm" key={0} className="mr-2" color={'#ffffff'}></Spinner> {t('TEMPLATES.CREATING_TEMPLATE')}
                              </>) : (<>
                                <FiFilePlus style={{ marginRight: 10 }} size={22} /> {t('TEMPLATES.CREATE_TEMPLATE')}
                              </>)}


                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => createSingleTemplate('document')}><SiMicrosoftword style={{ marginRight: 10 }} size={22} /> {t('TEMPLATES.DOCUMENT')}</Dropdown.Item>
                              <Dropdown.Item onClick={() => createSingleTemplate('spreadsheets')}><SiMicrosoftexcel style={{ marginRight: 10 }} size={22} />  {t('TEMPLATES.SPREADSHEET')}</Dropdown.Item>
                              <Dropdown.Item onClick={() => createSingleTemplate('presentation')}><SiMicrosoftpowerpoint style={{ marginRight: 10 }} size={22} /> {t('TEMPLATES.PRESENTATION')}</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body><TemplateList /></Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <Card.Header>{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.SELECTED_TAMPLATES')}</Card.Header>
                    <Card.Body>
                      <ListGroup>
                        {(props?.customForms?.selectedForm?.templates || []).map((template: any, i: number) => {

                          return (<ListGroup.Item className="list-item">
                            <div className="d-flex align-items-center justify-content-between">
                              <span style={{ color: '#2C42FF', cursor: 'pointer', fontWeight: 'bolder' }} onClick={() => props?.setTemplateDataInCustomForms(template)}>{template?.filename}</span>
                              <Button
                                variant="link"
                                onClick={event => handleRemoveTemplateFromCustomForm(template, i)}

                              >
                                <i className="icon">
                                  <span>
                                    <FeatherIcons icon="trash-2"></FeatherIcons>
                                  </span>
                                </i>
                              </Button>
                            </div>
                          </ListGroup.Item>)
                        })}
                      </ListGroup>
                    </Card.Body>
                  </Card>

                </Col>
              </Row>

            </Tab.Pane>
            <Tab.Pane active={activeTab === "settings"} eventKey="settings">
              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.FORM_STATUS')}</Form.Label>

                    <Select
                      key={'formStatus'}
                      isMulti={false}
                      name={'formStatus'}
                      value={
                        {
                          label: formStatuses[props?.customForms?.selectedForm?.formStatus || false].title,
                          value: props?.customForms?.selectedForm?.formStatus,
                          name: 'formStatus'
                        }
                      }
                      options={[
                        {
                          label: t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.FORM_STATUS_ACTIVE'),
                          value: true,
                          name: 'formStatus'
                        },
                        {
                          label: t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.FORM_STATUS_PASSIVE'),
                          value: false,
                          name: 'formStatus'
                        }
                      ]}
                      className="react-select react-select-container"
                      classNamePrefix="react-select"
                      onChange={(e) => handleSaveStatus(e)}
                    ></Select>
                    <Form.Text muted>
                      {props?.helpText || ''}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-1 mt-2">
                    <Form.Label>Success Message</Form.Label>
                    <Form.Control
                      type="text"
                      key={'successMessage'}
                      name="title"
                      defaultValue={props?.customForms?.selectedForm?.successMessage || 'Your form has been successfully submitted.'}
                      onBlur={(e: any) => {
                        props.editForm({
                          ['successMessage']: e?.target?.value,
                        })
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={shareModal}
        onHide={toggleShareModal}
        size="lg"
        centered
        contentClassName="modal-shadow-border"
      >
        <Modal.Header closeButton className="bg-secondary">
          <h4 className="modal-title text-white">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.SHARE')}</h4>
        </Modal.Header>
        <Modal.Body>

          {(() => {
            let link = `${window.location.host.includes('localhost') ? 'http://' + window.location.host : 'https://' + window.location.host}/form/${props?.companies?.company?.uid}/${props?.customForms?.selectedForm?.uid}`;
            let iframe = `<iframe width="600px" height="800px" src="${link}"></iframe>`
            return (
              <>
                <div className="d-flex align-items-end justify-content-between gap-1 mb-2">
                  <TagsInput
                    value={selected}
                    onChange={setSelected}
                    beforeAddValidate={(tag) => {
                      if (trim(tag).length <= 0) {
                        return false;
                      }
                      if (!validateEmail(tag)) {
                        toast.warning(t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.INVALID_EMAIL'));
                        return false;
                      }
                      return true;
                    }}
                    name="emails"
                    placeHolder="Press enter to add"
                  />
                  <Button className="w-25 btn btn-primary" variant="primary" onClick={() => {
                    if (selected.length <= 0) {
                      toast.warning(t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.INVALID_EMAIL'));
                      return;
                    }
                    setSendEmailModal(true)
                  }}>{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.SEND_EMAIL')}</Button>
                </div>
                <div className="d-flex align-items-center justify-content-between gap-1 mb-2">
                  <input type="text" readOnly value={link}
                    onClick={event => {
                      toast.info(t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.LINK_COPIED'))
                      navigator.clipboard.writeText(`${link}`)
                    }}
                    className="form-control" />
                  <Button
                    className="w-25"
                    onClick={event => {
                      toast.info(t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.LINK_COPIED'))
                      navigator.clipboard.writeText(`${link}`)
                    }}
                    variant="outline-primary"><FeatherIcons size={15} icon={'link'} /> {t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.COPY_LINK')}</Button>
                </div>
                <div className="d-flex align-items-center justify-content-between gap-1 mb-2">
                  <input
                    onClick={event => {
                      toast.info(t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.CODE_COPIED'))
                      navigator.clipboard.writeText(`${iframe}`)
                    }}
                    className="form-control w-100" readOnly value={`${iframe}`} />
                  <Button
                    onClick={event => {
                      toast.info(t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.CODE_COPIED'))
                      navigator.clipboard.writeText(`${iframe}`)
                    }}
                    variant="outline-primary w-25"><FeatherIcons size={15} icon={'code'} /> HTML Iframe</Button>
                </div>

                <div>
                  <div className="d-flex align-items-center justify-content-left gap-1">
                    <h4>{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.PREVIEW')}</h4>
                  </div>
                  <div>
                    <iframe title={`${props?.customForms?.selectedForm?.title}`} style={{
                      width: '100%',
                      minHeight: '400px'
                    }} src={`${link}`}></iframe>
                  </div>
                </div>
              </>
            )
          })()}

        </Modal.Body>
      </Modal>

      <Modal
        show={sendEmailModal}
        onHide={toggleSendEmailModal}
        centered
        contentClassName="modal-shadow-border"
      >
        <Modal.Header closeButton className="bg-secondary">
          <h4 className="modal-title text-white">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.SEND_EMAIL')}</h4>
        </Modal.Header>
        <Modal.Body>




          <Form.Group className="mb-2" controlId="formBasicEmail">
            <Form.Label className="mb-0">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.RECEIVERS')}</Form.Label>
            <TagsInput
              value={selected}
              onChange={setSelected}
              beforeAddValidate={(tag) => {
                if (trim(tag).length <= 0) {
                  return false;
                }
                if (!validateEmail(tag)) {
                  toast.warning(t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.INVALID_EMAIL'));
                  return false;
                }
                return true;
              }}
              name="emails"
              placeHolder="Press enter to add"
            />
          </Form.Group>


          <Form.Group className="mb-2" controlId="formBasicEmail">
            <Form.Label className="mb-0">{t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.MESSAGE_TEXT')}</Form.Label>
            <Form.Control
              onChange={event => {
                setMessage(event.target.value)
              }}
              value={message}
              as="textarea" rows={3} />
          </Form.Group>

          <Button
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              if (selected.length <= 0) {
                toast.warning(t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.INVALID_EMAIL'));
                return;
              }
              if (message.length <= 0) {
                toast.warning(t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.INVALID_MESSAGE'));
                return;
              }
              sendEmail()
            }}
            variant="primary" type="submit">
            {t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.CONFIRM_AND_SEND')}
          </Button>

        </Modal.Body>
      </Modal>

    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(CreateContractType);
