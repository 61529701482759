import { connect } from "react-redux";

import { Card, Row, Col } from "react-bootstrap";
import TinyCard from "@pages/recep/components/TinyCard";
import FeatherIcons from 'feather-icons-react';
import moment from 'moment'
import { MdOutlineTimer } from "react-icons/md"
import { useTranslation } from "react-i18next";
const mapStateToProps = (state: any) => ({});

const mapActionsToProps = {};

const InfoCards: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="co-info-boxes">

      <div className="co-info-box">
        <div className="co-info-box-icon">
          <FeatherIcons icon={'calendar'} size={36} className="align-self-center" style={{
            marginRight: "10px"
          }}></FeatherIcons>

        </div>
        <div className="co-info-box-content">
          <h5 className="mt-0 mb-0">
            {
              props?.overview?.startDate
                ? moment(props?.overview?.startDate).format('DD/MM/YYYY')
                : '-'
            }
          </h5>
          <span className="text-muted">{t("CONTRACTS.OVERVIEW.INFO_CARDS.START_DATE")}</span>
        </div>



      </div>

      <div className="co-info-box">
        <div className="co-info-box-icon">
          <FeatherIcons icon={'clock'} size={36} className="align-self-center" style={{
            marginRight: "10px"
          }}></FeatherIcons>

        </div>
        <div className="co-info-box-content">
          <h5 className="mt-0 mb-0">{props?.overview?.endDate ? moment(props?.overview?.endDate).format('DD/MM/YYYY') : '-'}</h5>
          <span className="text-muted">{t("CONTRACTS.OVERVIEW.INFO_CARDS.END_DATE")}</span>
        </div>



      </div>


      <div className="co-info-box">
        <div className="co-info-box-icon">
          <FeatherIcons icon={'alert-octagon'} size={36} className="align-self-center" style={{
            marginRight: "10px"
          }}></FeatherIcons>

        </div>
        <div className="co-info-box-content">
          <h5 className="mt-0 mb-0">
            {
              props?.overview?.effectiveDate
                ? moment(props?.overview?.effectiveDate).format('DD/MM/YYYY')
                : '-'
            }</h5>
          <span className="text-muted">
            {t("CONTRACTS.OVERVIEW.INFO_CARDS.EFFECTIVE_DATE")}
          </span>
        </div>



      </div>
      <div className="co-info-box">
        <div className="co-info-box-icon">
          <MdOutlineTimer size={36} className="align-self-center" style={{
            marginRight: "10px"
          }} />

        </div>
        <div className="co-info-box-content">
          <h5 className="mt-0 mb-0">{props?.overview?.renewalDate ? moment(props?.overview?.renewalDate).format('DD/MM/YYYY') : '-'}</h5>
          <span className="text-muted">{t("CONTRACTS.OVERVIEW.INFO_CARDS.RENEWAL_DATE")}</span>
        </div>



      </div>






    </div>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(InfoCards);
