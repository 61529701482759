import React, { useEffect, useState } from "react";
import ScreenModal from "@components/Modals/ScreenModal";
import { connect } from 'react-redux';
import "./CreateCompany.scss";
import globalStyles from "@styles/globalStyles";
import { Form } from "react-bootstrap";
import ButtonLoader from "@components/Buttons/ButtonLoader";
import { GrChapterAdd, GrAdd } from 'react-icons/gr';
import { BsFillCursorFill, BsSearch } from 'react-icons/bs';
import { HiViewGridAdd } from 'react-icons/hi';

import { setNewCompanyData } from "@redux/components/index.actions";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({
    users: state.users,
    components: state.components
});

const mapActionsTopProps = {
    setNewCompanyData
}


const RenderFooterWithRedux: React.FunctionComponent<{
    users: any,
    components: any,
    setNewCompanyData: any,
    batchOperations: {
        onClose: () => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    }
}> = (props) => {
    const { t } = useTranslation()
    const [disabled, setDisabled] = useState<boolean>(true);
    useEffect(() => {
        const keys: string[] = Object.keys(props?.components?.newCompanyData?.companyData);
        const isEmpty = keys.map((key: string) => {
            return props?.components?.newCompanyData?.companyData[key];
        }).some((value: any) => {
            return value === null || value === "" || value === undefined;
        })
        if (isEmpty) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [props?.components?.newCompanyData?.companyData])
    return (
        <React.Fragment>
            {props?.batchOperations?.index > 0 && (
                <button className="btn btn-outline-primary companyOnboard-previous" onClick={props?.batchOperations?.previous}>
                    {t("POPUPS.COMPANY_ONBOARDING.PREVIOUS")}
                </button>
            )
            }
            {(
                <button className="btn btn-primary companyOnboard-next" disabled={disabled} onClick={() => {
                    props?.batchOperations?.next()

                    if (!isEmpty) {
                        props?.batchOperations?.next()
                    }
                }}>
                    {t("POPUPS.COMPANY_ONBOARDING.NEXT")}
                </button>
            )}
        </React.Fragment>
    )
}


export const renderFooter = connect(mapStateToProps, mapActionsTopProps)(RenderFooterWithRedux);

const CreateCompany: React.FunctionComponent<{
    users: any,
    components: any,
    setNewCompanyData: any,
    batchOperations: {
        onClose: () => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    }
}> = (props) => {
    const { t } = useTranslation();
    const onInput = (e: any) => {
        const key = e.currentTarget.name;
        const value = e.currentTarget.value;
        props?.setNewCompanyData({
            key: "companyData",
            value: {
                ...props?.components?.newCompanyData?.companyData,
                [key]: value
            }
        })

    }
    const handleSubmit = (e: any) => {
    }

    return (
        <div className="container-fluid create-company">
            <div className="row create-company-row">
                <div className="col-lg-7 col-md-12">
                    <div className="innerContent">
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group className="mb-4" controlId="emailValidation">
                                <Form.Label htmlFor="title">
                                    {t("POPUPS.COMPANY_ONBOARDING.TITLE_LABEL")}
                                </Form.Label>
                                <Form.Control
                                    onInput={onInput}
                                    id="title"
                                    name="title"
                                    type="text"
                                    placeholder={t("POPUPS.COMPANY_ONBOARDING.TITLE_PLACEHOLDER")}
                                    defaultValue={props?.components?.newCompanyData?.companyData?.title}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t("POPUPS.COMPANY_ONBOARDING.TITLE_FEEDBACK")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4" controlId="emailValidation">
                                <Form.Label htmlFor="website">
                                    {t("POPUPS.COMPANY_ONBOARDING.WEBSITE_LABEL")}
                                </Form.Label>
                                <Form.Control
                                    onInput={onInput}
                                    id="website"
                                    name="website"
                                    type="text"
                                    placeholder={t("POPUPS.COMPANY_ONBOARDING.WEBSITE_PLACEHOLDER")}
                                    defaultValue={props?.components?.newCompanyData?.companyData?.website}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t("POPUPS.COMPANY_ONBOARDING.WEBSITE_FEEDBACK")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4" controlId="emailValidation">
                                <Form.Label htmlFor="description">
                                    {t("POPUPS.COMPANY_ONBOARDING.DESCRIPTION_LABEL")}
                                </Form.Label>
                                <Form.Control
                                    onInput={onInput}
                                    id="description"
                                    name="description"
                                    type="text"
                                    as="textarea"
                                    rows={10}
                                    placeholder={t("POPUPS.COMPANY_ONBOARDING.DESCRIPTION_PLACEHOLDER")}
                                    defaultValue={props?.components?.newCompanyData?.companyData?.description}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t("POPUPS.COMPANY_ONBOARDING.DESCRIPTION_FEEDBACK")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4" style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                                controlId="companyNameValidation">
                                <div>

                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <div className="col-lg-5 d-lg-block d-md-none">
                    <div className="sideBarContent">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapActionsTopProps)(CreateCompany);