import React, { useEffect, useState } from "react";

import { Card, ListGroup, OverlayTrigger, Tooltip, Button, Navbar, Nav, Tab } from "react-bootstrap";
import FeatherIcons from "feather-icons-react";
import { getListCustomFields } from "@redux/contract/index.actions";
import { toggleGenericAddCustomFieldModal } from "@redux/components/index.actions";
import EditingFieldListItem from "@popups/EditCustomFormsPopup/components/EditingFieldListItem";
import { connect } from "react-redux";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import NewCustomFieldList from "./NewCustomFieldList";

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts,
  customForms: state.customForms
});
const mapActionsToProps = {
  getListCustomFields,
  toggleGenericAddCustomFieldModal
};

const EditingFieldList: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();


  const [activeTab, setactiveTab] = useState<string>("customfields");
  const handleSelect = (eventKey: string | null) => {
    setactiveTab(eventKey!);
  };

  useEffect(() => {
    props.getListCustomFields();
  }, []);

  return (
    <Card>

      <Card.Header className="d-flex align-items-center justify-content-center p-1">

        <Navbar bg="">
          <Nav

            key={'tab1'}
            defaultActiveKey="customfields"
            as="ul"
            variant="tabs"
            justify
            activeKey={activeTab}
            onSelect={handleSelect}
          >


            <Nav.Item as="li" key={'index2'}>
              <Nav.Link style={{ whiteSpace: 'nowrap' }} eventKey="customfields">{t("Özel Alanlar")}</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key={'index1'}>
              <Nav.Link style={{ whiteSpace: 'nowrap' }} eventKey="newcustomfield">{t('Yeni Özel Alan')}</Nav.Link>
            </Nav.Item>

          </Nav>
        </Navbar>

      </Card.Header>
      <Card.Body className="p-1">


        <Tab.Content bsPrefix="tab-content  pt-1">
          <Tab.Pane active={activeTab === "customfields"} eventKey="customfields">
            <div className="custom-forms-field-list">
              <ListGroup as="ul">
                {(props?.contracts?.fields || []).map((item: any, index: number) => {
                  if (!(props?.customForms?.selectedForm?.formFields || []).map((e: any) => e?.field?._id).includes(item._id)) {


                    return (
                      <ListGroup.Item
                        key={index}
                        as="li"
                        className="rounded mb-1 border-1"
                      >
                        <EditingFieldListItem
                          item={item}
                          contractTypeId={props?.contractTypeId}
                        />
                      </ListGroup.Item>
                    )
                  }

                })}
              </ListGroup>

            </div>
          </Tab.Pane>
          <Tab.Pane active={activeTab === "newcustomfield"} eventKey="newcustomfield">
            <NewCustomFieldList></NewCustomFieldList>
          </Tab.Pane>

        </Tab.Content>




      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(EditingFieldList);
