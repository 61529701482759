import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { Reducer } from "redux";
import _ from 'lodash';

const initialState = {
    genericErrorModal: {
        show: false,
        code: null,
        message: null,
        status: null
    },
    genericSuccessModal: {
        show: false,
        code: null,
        message: null,
        status: null
    },
    showNewCompanyModal: false,
    showAddNewUserToCompanyModal: false,
    showCompanySwitchModal: false,
    showUploadFileModal: false,
    showFilesVersions: false,
    newCompanyData: {
        isNewCompany: null,
        companyData: {
            title: null,
            website: null,
            description: null
        },
        surveyData: {
            role: null,
            whatToDoWithSystem: null,
            size: null,
            howManyPeople: null
        }
    },
    newCustomFieldModal: false,
    newPipelineModal: false,
    editCustomFormsModal: false,
    newContractTypeModal: false,
    newStatusModal: false,
    newContractCompaniesModal: false,
    newContractModal: false,
    googleDocsIFrameModal: false,
    googleFilePermissionModal: false,
    templateGoogleDocsIFrameModal: false,
    pageTitle: "",
    contractOverviewModal: false,
    filesPdfViewerModal: {
        show: false,
        file: {},
        url: '',
        isFetching: false,
    },
}

const ComponentsReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {

        case ActionDispatchTypes.LOGOUT:
            return {
                ...initialState
            }
        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_ERROR_MODAL:
            return {
                ...state,
                genericErrorModal: {
                    show: !state.genericErrorModal?.show,
                    code: !state.genericErrorModal?.show
                        ? action.payload?.code
                        : null,
                    message: !state.genericErrorModal?.show
                        ? action.payload?.message
                        : null,
                    status: !state.genericErrorModal?.show
                        ? action.payload?.status
                        : null
                }
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_SUCCESS_MODAL:
            return {
                ...state,
                genericSuccessModal: {
                    show: !state.genericSuccessModal?.show,
                    code: !state.genericSuccessModal?.show
                        ? action.payload?.code
                        : null,
                    message: !state.genericSuccessModal?.show
                        ? action.payload?.message
                        : null,
                    status: !state.genericSuccessModal?.show
                        ? action.payload?.status
                        : null
                }
            }

        case ActionDispatchTypes.TOGGLE_NEW_COMPANY_MODAL:
            return {
                ...state,
                showNewCompanyModal: _.isNull(action?.payload)
                    ? !state.showNewCompanyModal
                    : action?.payload
            }
        case ActionDispatchTypes.SET_NEW_COMPANY_DATA:
            return {
                ...state,
                newCompanyData: {
                    ...state.newCompanyData,
                    [action.payload?.key]: action.payload?.value
                }
            }
        case ActionDispatchTypes.DELETE_NEW_COMPANY_DATA:
            return {
                ...state
            }
        case ActionDispatchTypes.CREATE_COMPANY_SUCCESSFUL:
            return {
                ...state,
                showAddNewUserToCompanyModal: true,
                showNewCompanyModal: false,
                newCompanyData: {
                    ...initialState?.newCompanyData
                }
            }
        case ActionDispatchTypes.TOGGLE_ADD_USER_TO_COMPANY_MODAL:
            return {
                ...state,
                showAddNewUserToCompanyModal: _.isNull(action?.payload)
                    ? !state.showAddNewUserToCompanyModal
                    : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_COMPANY_SWITCH_MODAL:
            return {
                ...state,
                showCompanySwitchModal: _.isNull(action?.payload)
                    ? !state.showCompanySwitchModal
                    : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_CUSTOM_FIELD_MODAL:
            return {
                ...state,
                newCustomFieldModal: _.isNull(action?.payload) ? !state.newCustomFieldModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_PIPELINE_MODAL:
            return {
                ...state,
                newPipelineModal: _.isNull(action?.payload) ? !state.newPipelineModal : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_CONTRACT_TYPE_MODAL:
            return {
                ...state,
                newContractTypeModal: _.isNull(action?.payload) ? !state.newContractTypeModal : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_EDIT_CUSTOM_FORMS_MODAL:
            return {
                ...state,
                editCustomFormsModal: _.isNull(action?.payload) ? !state.editCustomFormsModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_STATUS_MODAL:
            return {
                ...state,
                newStatusModal: _.isNull(action?.payload) ? !state.newStatusModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_CONTRACT_COMPANIES_MODAL:
            return {
                ...state,
                newContractCompaniesModal: _.isNull(action?.payload) ? !state.newContractCompaniesModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_CONTRACT_MODAL:
            return {
                ...state,
                newContractModal: _.isNull(action?.payload) ? !state.newContractModal : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_UPLOAD_FILES_MODAL:
            return {
                ...state,
                showUploadFileModal: action?.payload
            }

        case ActionDispatchTypes.TOGGLE_FILES_VERSION_MODAL:
            return {
                ...state,
                showFilesVersions: state?.showFilesVersions ? false : true
            }

        case ActionDispatchTypes.SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: action?.payload || ''
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_GOOGLE_DOCS_IFAME_MODAL:
            return {
                ...state,
                googleDocsIFrameModal: _.isNull(action?.payload) ? !state.googleDocsIFrameModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_GOOGLE_FILE_PERMISSION_MODAL:
            return {
                ...state,
                googleFilePermissionModal: _.isNull(action?.payload) ? !state.googleFilePermissionModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_TEMPLATE_GOOGLE_DOCS_IFAME_MODAL:
            return {
                ...state,
                templateGoogleDocsIFrameModal: _.isNull(action?.payload) ? !state.googleDocsIFrameModal : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_CONTRACT_OVERVIEW_MODAL:
            return {
                ...state,
                contractOverviewModal: _.isNull(action?.payload) ? !state.contractOverviewModal : action?.payload
            }
        case ActionDispatchTypes.CLOSE_CDN_POPUP:
            return {
                ...state,
                showUploadFileModal: false
            }

        case ActionDispatchTypes?.TOGGLE_FILES_PDF_VIEWER:
            //window.location.href = "/";
            return {
                ...state,
                filesPdfViewerModal: { ...action?.payload }
            }

        default:
            return state;
    }
}

export default ComponentsReducer;