import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { excelCreator } from "@utils/export/excelCreator"
import ErrorResponse from '@redux/utils/ErrorResponse.utils';

import api from '@clients/api.client';
import { toast } from 'react-toastify';
import { TAction, TGenericResponse } from "@redux/interfaces/index.interfaces";

import { toggleGenericErrorModal } from '@redux/utils/components/GenerateErrorModal';
import { toggleGenericSuccessModal } from '@redux/utils/components/GenerateSuccessModal';
import moment from 'moment';


export const addCustomForm: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;

    dispatch({
        type: ActionDispatchTypes.ADD_CUSTOM_FORMS,
        payload
    })
    return api.post(`/custom-forms/${company}`, {
        title: payload?.title,
    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.ADD_CUSTOM_FORMS_SUCCESSFUL,
                payload: response?.data?.node
            })
            dispatch({
                type: ActionDispatchTypes.TOGGLE_GENERIC_EDIT_CUSTOM_FORMS_MODAL,
                payload: !getState().components.editCustomFormsModal
            });
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.ADD_CUSTOM_FORMS_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const addCustomFieldsToCustomForm: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    // custom-forms/field/
    dispatch({
        type: ActionDispatchTypes.ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS,
        payload
    })
    return api.post(`/custom-forms/field/${company}`, { ...payload })
        .then((response: any) => {
            //toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const getTableCustomForms: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_TABLE_CUSTOM_FORMS,
        payload
    })

    return api.get(`/custom-forms/list/${company}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_TABLE_CUSTOM_FORMS_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_TABLE_CUSTOM_FORMS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}
export const removeFieldFromForm: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const formId = getState().customForms?.selectedForm?._id;
    const fieldId = payload?.fieldId || getState().customForms?.editSelectedFormFieldData?._id;
    dispatch({
        type: ActionDispatchTypes.REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS,
        payload
    })

    return api.delete(`/custom-forms/field/${company}/${formId}/${fieldId}`)
        .then((response: any) => {
            dispatch({
                type: ActionDispatchTypes.REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS_SUCCESSFUL,
                payload: response.data.node
            })
            return response.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS_FAILED,
                payload: errorResponse
            });
            return errorResponse;
        })
}
export const deleteForm: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const formId = payload?.deletedFormId;

    dispatch({
        type: ActionDispatchTypes.DELETE_CUSTOM_FORMS,
        payload
    })

    return api.delete(`/custom-forms/${company}/${formId}`)
        .then((response: any) => {
            toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.DELETE_CUSTOM_FORMS_SUCCESSFUL,
                payload: response.data.node
            })
            return response.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_CUSTOM_FORMS_FAILED,
                payload: errorResponse
            });
            return errorResponse;
        })
}


export const editForm: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const formId = getState().customForms?.selectedForm?._id;

    dispatch({
        type: ActionDispatchTypes.EDIT_CUSTOM_FORMS,
        payload
    })
    console.log('payload', payload)
    return api.put(`/custom-forms/${company}/${formId}`, { ...payload })
        .then((response: any) => {
            //toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.EDIT_CUSTOM_FORMS_SUCCESSFUL,
                payload: response.data.node,
                setData: payload
            })
            return response.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.EDIT_CUSTOM_FORMS_FAILED,
                payload: errorResponse
            });
            return errorResponse;
        })
}


export const reOrderFieldsInCustomForm: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const formId = getState().customForms?.selectedForm?._id;

    dispatch({
        type: ActionDispatchTypes.REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS,
        payload
    })

    return api.put(`/custom-forms/order-field/${company}/${formId}`, { ...payload })
        .then((response: any) => {
            dispatch({
                type: ActionDispatchTypes.REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS_SUCCESSFUL,
                payload: payload
            })
            return response.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS_FAILED,
                payload: errorResponse
            });
            return errorResponse;
        })
}

export const setCustomFormsFieldEditData: any = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.SET_CUSTOM_FORMS_FIELD_EDIT_DATA,
        payload
    })

}
export const setEditData: any = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.SET_EDIT_CUSTOM_FORMS_DATA,
        payload: payload || {}
    })
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_EDIT_CUSTOM_FORMS_MODAL,
        payload: !getState().components.editCustomFormsModal
    });
}

export const editFieldData: any = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.SET_EDIT_CUSTOM_FORMS_DATA,
        payload
    })
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_EDIT_CUSTOM_FORMS_MODAL,
        payload: !getState().components.editCustomFormsModal
    });
}
export const editFormFieldReducer: any = (payload: any) => (dispatch: any, getState: any) => {

    const company = getState().companies?.company?._id;
    const formId = getState().customForms?.selectedForm?._id;
    const fieldId = payload?.fieldId || getState().customForms?.editSelectedFormFieldData?._id;


    dispatch({
        type: ActionDispatchTypes.SET_CUSTOM_FORMS_FIELD_DATA_TO_REDUCER,
        payload: {
            ...payload,
            fieldId
        }
    })
}

export const editFormField: any = (payload: any) => (dispatch: any, getState: any) => {

    const company = getState().companies?.company?._id;
    const formId = getState().customForms?.selectedForm?._id;
    const fieldId = payload?.fieldId || getState().customForms?.editSelectedFormFieldData?._id;


    dispatch({
        type: ActionDispatchTypes.EDIT_CUSTOM_FORMS_FIELD_DATA,
        payload: {
            ...payload,
            fieldId
        }
    })

    return api.put(`/custom-forms/edit-field/${company}/${formId}/${fieldId}`, {
        [payload.targetName]: payload?.targetValue
    })
        .then((response: any) => {
            //toast.success(response?.data?.response?.message);
            dispatch({
                type: ActionDispatchTypes.EDIT_CUSTOM_FORMS_FIELD_DATA_SUCCESSFUL,
                payload: response.data.node
            })

            return response.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.EDIT_CUSTOM_FORMS_FIELD_DATA_FAILED,
                payload: errorResponse
            });
            return errorResponse;
        })
}

export const selectTemplate: any = (payload: any) => (dispatch: any, getState: any) => {

    const company = getState().companies?.company?._id;
    const formId = getState().customForms?.selectedForm?._id;


    dispatch({
        type: ActionDispatchTypes.CUSTOM_FORMS_SELECT_TEMPLATE,
        payload: {
            ...payload,
            formId
        }
    })

    return api.put(`/custom-forms/template/${company}/${formId}`, {
        templateId: payload?._id
    })
        .then((response: any) => {
            //toast.success(response?.data?.response?.message);
            dispatch({
                type: ActionDispatchTypes.CUSTOM_FORMS_SELECT_TEMPLATE_SUCCESSFUL,
                payload: response.data.node
            })
            return response.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.CUSTOM_FORMS_SELECT_TEMPLATE_FAILED,
                payload: errorResponse
            });
            return errorResponse;
        })
}
export const removeTemplateFromCustomForms: any = (payload: any) => (dispatch: any, getState: any) => {

    const company = getState().companies?.company?._id;
    const formId = getState().customForms?.selectedForm?._id;
    const templateId = payload?._id;

    dispatch({
        type: ActionDispatchTypes.CUSTOM_FORMS_REMOVE_FROM_TEMPLATE,
        payload: {
            ...payload,
            formId
        }
    })

    return api.delete(`/custom-forms/template/${company}/${formId}/${templateId}`)
        .then((response: any) => {
            toast.success(response?.data?.response?.message);
            dispatch({
                type: ActionDispatchTypes.CUSTOM_FORMS_REMOVE_FROM_TEMPLATE_SUCCESSFUL,
                payload: response.data.node,
                index: payload?.index
            })
            return response.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.CUSTOM_FORMS_REMOVE_FROM_TEMPLATE_FAILED,
                payload: errorResponse
            });
            return errorResponse;
        })
}
export const refreshTemplateList: any = (payload: any) => (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.CUSTOM_FORMS_TEMPLATE_REFRESH,
        payload
    })

    dispatch({
        type: ActionDispatchTypes.CUSTOM_FORMS_TEMPLATE_REFRESH_SUCCESSFUL,
        payload
    })

}

