import React, { useEffect, useState } from "react";

import { Col, Modal, Row, Button } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import ContractsSelect from '@components/SelectsPaginated/Contracts';
import FilesSelect from '@components/SelectsPaginated/Files'

import api from '@clients/api.client';

import { toggleGenericGoogleDocsIframeModal, toggleFilesVersionModal } from "@redux/components/index.actions";
import { setFileData, updateFile, getFileVersions } from "@redux/files/index.actions";

import './index.scss';
import { toast } from "react-toastify";
import FeatherIcons from "feather-icons-react";

const mapStateToProps = (state: any) => ({
  components: state?.components,
  companies: state?.companies,
  files: state?.files
});
const mapActionsToProps = {
  toggleGenericGoogleDocsIframeModal,
  setFileData,
  updateFile,
  getFileVersions,
  toggleFilesVersionModal
};



const AddNewStatusModal = (props: any) => {
  const { t } = useTranslation();


  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (saving === true) {
      setTimeout(() => {
        setSaving(false);
      }, 1000);

    }
  }, [saving]);

  return (
    <Modal
      show={props?.components?.googleDocsIFrameModal}
      onHide={() => {
        props?.setFileData({});
        props?.toggleGenericGoogleDocsIframeModal()
        window.location.reload();
      }}
      size="xl"
      centered
      dialogClassName="modal-google-iframe"
      contentClassName="w-100 h-100"
    >
      <Modal.Header className="bg-primary">
        <h4 className="modal-title text-white">
          {t('TEMPLATES.DOCUMENT_EDITOR')}
        </h4>
        <div>
          <Button
            variant="secondary"
            className="text-white"
            onClick={() => {
              props?.setFileData({});
              props?.toggleGenericGoogleDocsIframeModal()
              window.location.reload();
            }}
          >
            {saving === true ? t("CONTRACTS_TYPES.SAVING") : t("CONTRACTS_TYPES.SAVED")}
          </Button>
          <span>
            <Button
              onClick={() => {
                props?.setFileData({});
                props?.toggleGenericGoogleDocsIframeModal()
                window.location.reload();
              }}
              className="text-white"
              variant="link"
            >
              <FeatherIcons icon="x" />
            </Button>
          </span>
        </div>
      </Modal.Header>
      <Modal.Body className="iframe-wrapper">

        <div className="gdocs-iframe-left">
          <iframe className="google-docs-iframe"
            src={`https://docs.google.com/${props?.files?.fileData?.documentTypeForUrl}/d/${props?.files?.fileData?.file?.key}?rm=embedded`}></iframe>
        </div>


        <div className="gdocs-iframe-right">
          <label>{t("FILES.FILENAME")} </label>
          <input
            onBlur={(e: any) => {
              props?.updateFile({
                _id: props?.files?.fileData?.file?._id,
                filename: e?.target.value || null
              }).then(() => {
                setSaving(true);
              })
            }} type="text" className="input-group-text w-100" defaultValue={props?.files?.fileData?.file?.filename} />
          <label>{t("FILES.TAGS")} </label>
          <input onBlur={(e: any) => {
            props?.updateFile({
              _id: props?.files?.fileData?.file?._id,
              tags: e?.target.value || null
            }).then(() => {
              setSaving(true);
            })
          }}
            type="text"
            className="input-group-text w-100" defaultValue={props?.files?.fileData?.file?.tags.join(", ")} />
          <label>{t("FILES.CONTRACT")} </label>
          <ContractsSelect
            onValueSubmit={(value: any) => {
              api.put(`/files/${props?.files?.fileData?.file?._id}`, {
                contract: value,
                company: props?.companies?.company?._id
              }).then((response: any) => {
                //toast.success(response?.data?.response?.message);
                setSaving(true);
              }).catch((err: any) => {
                toast.error(err?.response?.data?.response?.message);
              })
            }}

            defaultOption={{
              value: props?.files?.fileData?.file?.contract?._id,
              label: props?.files?.fileData?.file?.contract?.title
            }}
          />

          {(props?.files?.fileData?.file?.versionCount === 0 || props?.files?.fileData?.file?.versionCount == null) ? (
            <><label>{t("FILES.VERSION")} </label>
              <div className="d-flex align-items-center justify-content-between gap-1">
                <div className="w-100">
                  <FilesSelect
                    hiddenIds={(props?.files?.uploadedFiles || []).map((item: any) => item?._id)}
                    onValueSubmit={(value: any) => {
                      console.log('first version', value);
                      api.put(`/files/version/${props?.companies?.company?._id}/${props?.files?.fileData?.file?._id}`, {
                        fileId: value?.value
                      }).then((response: any) => {
                        //toast.success(response.data?.response?.message);
                        setSaving(true)
                      }).catch((error: any) => {
                        const errorMessage = error?.response?.data?.message || error?.message;
                        toast.error(errorMessage)
                      })
                    }}
                  />
                </div>

              </div></>
          ) : null}

        </div>


      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(AddNewStatusModal);
