import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Container, Card } from "react-bootstrap";
import FormInput from "@components/FormInput";
import Select from "react-select";
import moment from "moment";
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons/dist/textMaskAddons';


type CustomFieldAreaProps = {
    children?: React.ReactNode
}

const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    allowNegative: false,
    decimalLimit: false,
    allowLeadingZeroes: false,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',' // default is '.'

})

export type TCustomComponent = 'Text' | 'TextArea' | 'Number' | 'Date' | 'Time' | 'Divider' | 'Header' | 'SingleSelect'

export type CSInputTextProps = {
    size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    key?: string | number
    label?: string
    helpText?: string | null
    require?: boolean
    name: string
    value?: any
    isDisabled?: boolean
    className?: string
    onInput?: React.FormEventHandler<HTMLInputElement>
    onChange?: React.FormEventHandler<HTMLInputElement>
    data?: any
}



const CustomFormFieldArea = ({ children }: CustomFieldAreaProps) => {


    return (
        <Card>
            <Card.Header>
                <Card.Title>Custom Fields</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    {children}
                </Row>
            </Card.Body>
        </Card>
    )
}


const Header = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <h1>{props?.label}</h1>
            <p>{props?.helpText}</p>
        </Col>
    )

}
const Divider = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <div style={{ width: '100%', height: '2px', background: 'black', boxShadow: '1px 1px 5px #cccccc' }}><hr /></div>
        </Col>
    )

}

const Text = (props: CSInputTextProps) => {
    console.log('proval', props)
    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <FormInput
                    key={props?.key + '_input'}
                    disabled={props?.isDisabled}
                    className={props?.className}
                    name={props?.name}
                    type="text"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                    onChange={props?.onChange}
                />
                <Form.Text muted>
                    {props?.helpText || ''}
                </Form.Text>
            </Form.Group>
        </Col>
    )

}

const SingleSelect = (props: CSInputTextProps) => {

    console.log(props?.data?.field?.OptionValues)
    const [option, setOption] = useState<any>({ value: props?.value, label: props?.value })


    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}

                <Select
                    key={props?.key + '_input'}

                    isDisabled={props?.isDisabled}
                    isClearable={true}
                    isMulti={false}
                    defaultValue={option}
                    name={props?.name}
                    options={
                        props?.data?.field?.OptionValues?.map((opt: any) => {
                            return {
                                value: opt?._id,
                                label: opt?.title,
                                opt: opt
                            };
                        })
                    }
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    onChange={props?.onChange}
                ></Select>
                <Form.Text muted>
                    {props?.helpText || ''}
                </Form.Text>
            </Form.Group>
        </Col>
    )

}

const TextArea = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <FormInput
                    key={props?.key + '_input'}
                    disabled={props?.isDisabled || true}
                    className={props?.className}
                    name={props?.name}
                    type="textarea"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                />
                <Form.Text muted>
                    {props?.helpText || ''}
                </Form.Text>
            </Form.Group>
        </Col>
    )

}

const Number = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <MaskedInput
                    mask={currencyMask}
                    showMask={false}
                    guide={false}
                    className={props?.className}
                    name={props?.name}
                    type="text"
                    defaultValue={props?.value?.toString().replace('.', ',')}
                    required={props?.require}
                    onInput={props?.onInput}
                />
                <Form.Text muted>
                    {props?.helpText || ''}
                </Form.Text>
            </Form.Group>
        </Col>
    )

}

const Date = (props: CSInputTextProps) => {
    console.log(moment(props?.value).format('DD.MM.YYYY').toString())
    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <FormInput
                    key={props?.key + '_input'}
                    disabled={props?.isDisabled || true}
                    className={props?.className}
                    name={props?.name}
                    type="date"
                    defaultValue={props?.value ? moment(props?.value).format('YYYY-MM-DD').toString() : undefined}
                    required={props?.require}
                    onInput={props?.onInput}
                />
                <Form.Text muted>
                    {props?.helpText || ''}
                </Form.Text>
            </Form.Group>
        </Col>
    )

}

const Email = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <FormInput
                    key={props?.key + '_input'}
                    disabled={props?.isDisabled || true}
                    className={props?.className}
                    name={props?.name}
                    type="email"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                />
                <Form.Text muted>
                    {props?.helpText || ''}
                </Form.Text>
            </Form.Group>
        </Col>
    )

}

const Time = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <FormInput
                    key={props?.key + '_input'}
                    disabled={props?.isDisabled || true}
                    className={props?.className}
                    name={props?.name}
                    type="time"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                />
                <Form.Text muted>
                    {props?.helpText || ''}
                </Form.Text>
            </Form.Group>
        </Col>
    )

}

CustomFormFieldArea.Text = Text;
CustomFormFieldArea.TextArea = TextArea;
CustomFormFieldArea.Number = Number;
CustomFormFieldArea.Date = Date;
CustomFormFieldArea.Time = Time;
CustomFormFieldArea.Email = Email;
CustomFormFieldArea.SingleSelect = SingleSelect;
CustomFormFieldArea.Header = Header;
CustomFormFieldArea.Divider = Divider;

export default CustomFormFieldArea;