import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Container, Card } from "react-bootstrap";
import FormInput from "@components/FormInput";
import Select from "react-select";
import moment from "moment";
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons/dist/textMaskAddons';

import { Field, reduxForm } from 'redux-form';
import { trimEnd } from "lodash";
import { useTranslation } from "react-i18next";
type CustomFieldAreaProps = {
    children?: React.ReactNode
}

const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    allowNegative: false,
    decimalLimit: false,
    allowLeadingZeroes: false,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',' // default is '.'

})

export type TCustomComponent = 'Text' | 'TextArea' | 'Number' | 'Date' | 'Time'

export type CSInputTextProps = {
    size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    key?: string | number
    label?: string
    require?: boolean
    name: string
    value?: any
    className?: string
    onInput?: React.FormEventHandler<HTMLInputElement>
    onChange?: React.FormEventHandler<HTMLInputElement>
    data?: any
}



const CustomFieldArea = ({ children }: CustomFieldAreaProps) => {


    return (
        <Card>
            <Card.Header>
                <Card.Title>Custom Fields</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    {children}
                </Row>
            </Card.Body>
        </Card>
    )
}



const Text = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <FormInput
                    className={props?.className}
                    name={props?.name}
                    type="text"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                    onChange={props?.onChange}
                />

            </Form.Group>
        </Col>
    )

}

const SingleSelect = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}

                <Select
                    isClearable={true}
                    isMulti={false}
                    defaultValue={props?.value}
                    name={props?.name}
                    options={
                        props?.data?.field?.OptionValues?.map((opt: any) => {
                            return {
                                value: opt?._id,
                                label: opt?.title,
                                opt: opt
                            };
                        })
                    }
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    onChange={props?.onChange}
                ></Select>
            </Form.Group>
        </Col>
    )

}

const TextArea = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <FormInput
                    className={props?.className}
                    name={props?.name}
                    type="textarea"
                    value={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                />
            </Form.Group>
        </Col>
    )

}

const Number = (props: CSInputTextProps) => {
    const { t } = useTranslation();
    return (
        <Col key={props?.key} md={props?.size}>



            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <MaskedInput
                    mask={currencyMask}
                    showMask={false}
                    guide={false}
                    className={'form-control'}
                    name={props?.name}
                    type="text"
                    defaultValue={props?.value?.toString().replace('.', ',')}
                    required={props?.require}
                    onInput={props?.onInput}
                />
                <Form.Text className="text-muted">
                    {t('CUSTOM_FIELDS.TOUSHANDS_SEPARATOR')}
                </Form.Text>
            </Form.Group>
        </Col>
    )

}

const Date = (props: CSInputTextProps) => {
    console.log(moment(props?.value).format('DD.MM.YYYY').toString())
    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <FormInput
                    className={props?.className}
                    name={props?.name}
                    type="date"
                    defaultValue={props?.value ? moment(props?.value).format('YYYY-MM-DD').toString() : undefined}
                    required={props?.require}
                    onInput={props?.onInput}
                />
            </Form.Group>
        </Col>
    )

}

const Email = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <FormInput
                    className={props?.className}
                    name={props?.name}
                    type="email"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                />
            </Form.Group>
        </Col>
    )

}

const Time = (props: CSInputTextProps) => {

    return (
        <Col key={props?.key} md={props?.size}>
            <Form.Group>
                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                <FormInput
                    className={props?.className}
                    name={props?.name}
                    type="time"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                />
            </Form.Group>
        </Col>
    )

}

CustomFieldArea.Text = Text;
CustomFieldArea.TextArea = TextArea;
CustomFieldArea.Number = Number;
CustomFieldArea.Date = Date;
CustomFieldArea.Time = Time;
CustomFieldArea.Email = Email;
CustomFieldArea.SingleSelect = SingleSelect;

export default CustomFieldArea;