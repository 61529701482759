import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { createNewCustomField } from "@redux/contract/index.actions";
import { toggleGenericAddCustomFieldModal } from "@redux/components/index.actions";
import FeatherIcon from 'feather-icons-react';

// components
import { useTranslation } from "react-i18next";
import Select from "react-select";
const mapStateToProps = (state: any) => ({
  companies: state.companies,
});
const mapActionsToProps = {
  createNewCustomField,
  toggleGenericAddCustomFieldModal,
};

const CreateCustomFields = (props: any) => {


  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [internalName, setInternalName] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [required, setRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [existsOptionValues, setExistsOptionValues] = useState(false);

  const initialOptions: any = {
    title: "",
    value: "",
    key: "",
    alias: ""
  }

  const [options, setOptions] = useState([initialOptions]);

  const migrateData = props?.migrateData;

  const make_slug = (str: string) => {
    const letters: any = {
      İ: "i",
      I: "i",
      Ş: "s",
      Ğ: "g",
      Ü: "u",
      Ö: "o",
      Ç: "c",
      i: "i",
      ş: "s",
      ğ: "g",
      ü: "u",
      ö: "o",
      ç: "c",
      ı: "i",
    };
    str = str.replace(/(([İIŞĞÜÇÖiışğüçö]))/g, (letter) => letters[letter]);
    str = str.toLocaleLowerCase("tr-TR");
    str = str.replace(/[^a-z0-9]+/g, "-");
    str = str.replace(/^-+|-+$/g, "");
    return str;
  };
  const fieldOptions: {
    value: string;
    label: string;
    optionValues?: boolean;
  }[] = (
    [
      {
        key: "Text",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.TEXT"),
        optionValues: false
      },
      {
        key: "TextArea",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.TEXTAREA"),
        optionValues: false
      },
      {
        key: "Number",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.NUMBER"),
        optionValues: false
      },
      {
        key: "Email",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.EMAIL"),
        optionValues: false
      },
      {
        key: "Date",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.DATE"),
        optionValues: false
      },
      {
        key: "Time",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.TIME"),
        optionValues: false
      },
      {
        key: "SingleSelect",
        label: t("CONTRACTS_CUSTOM_FIELDS.CUSTOM_FIELDS_COMPONENT_NAMES.SINGLE_SELECTION"),
        optionValues: true
      },
    ] || []
  ).map(
    (
      item: {
        key: string;
        label: string;
        optionValues?: boolean
      },
      index: number
    ) => {
      return {
        value: item?.key,
        label: item?.label,
        optionValues: item?.optionValues
      };
    }
  );
  const handleChangeTitle = (title: string) => {
    setInternalName(make_slug(title));
    setTitle(title);
  };
  const handleSelectType = (data: any) => {
    setFieldType(data?.value);
    setExistsOptionValues(data?.optionValues);
  };
  const handleSwitch = (string: boolean) => {
    setRequired(string);
  };

  const handleChangeInternalName = (internalName: string) => {
    setInternalName(make_slug(internalName));
  };


  const handleOptionFields = (index: number, event: any) => {
    //setOptions([...options, options[index][event.target.name] = event.target.value])
    let optionsData = [...options];
    optionsData[index][event.target.name] = event.target.value

    if (event.target.name === 'title') {
      let prefix = title ? title + '-' : '';
      optionsData[index]['key'] = make_slug(prefix + event.target.value)
      optionsData[index]['value'] = event.target.value
    }

    setOptions(optionsData);

  }

  const handleRemoveOption = (index: number) => {
    //setOptions([...options, options[index][event.target.name] = event.target.value])
    let optionsData = [...options];

    if (optionsData.length <= 1) {
      return;
    }

    optionsData.splice(index, 1);

    setOptions(optionsData);
  }

  const handleSubmit = (e: any) => {



    e.preventDefault();



    setLoading(true);
    const requestData: {
      title: string;
      key: string;
      CustomComponent: string;
      extraProps: string;
      isDefault: string;
      OptionValues?: any[] | null,
      isOptional: boolean;
      isUnique: boolean;
      company: string;
    } = {
      title: title,
      key: internalName,
      CustomComponent: fieldType,
      isOptional: required,
      extraProps: "",
      isDefault: t("CONTRACTS_CUSTOM_FIELDS.THIS_IS_DEFAULT"),
      OptionValues: options.length ? options : null,
      isUnique: true,
      company: props?.companies?.currentCompany,
    };

    props
      .createNewCustomField(requestData)
      .then((res: any) => {
        if (res?.customField) {
          props.toggleGenericAddCustomFieldModal();
        }
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <Col md={12} className="mb-2">
            <Form.Label htmlFor="website">
              {t("CONTRACTS_CUSTOM_FIELDS.FIELD_TYPE_REQUIRED")}
            </Form.Label>
            <Select
              onChange={(e) => handleSelectType(e)}
              isMulti={false}
              placeholder={t("CONTRACTS_CUSTOM_FIELDS.SELECT_CUSTOM_COMPONENT_FIELD")}
              options={fieldOptions}
              className="react-select react-select-container select-role-type"
              classNamePrefix="react-select"
            />
            <Form.Control.Feedback type="invalid">
              {t("CONTRACTS_CUSTOM_FIELDS.FIELD_TYPE_FEEDBACK")}
            </Form.Control.Feedback>
          </Col>

          <Col md={12} className="mb-2">
            <Form.Label>{t("CONTRACTS_CUSTOM_FIELDS.TITLE")}</Form.Label>
            <Form.Control
              value={title || ""}
              style={{ width: "100%" }}
              onInput={(e) => handleChangeTitle(e.currentTarget.value)}
              id={"title"}
              name={"title"}
              type="text"
              placeholder={t("CONTRACTS_CUSTOM_FIELDS.TITLE_PLACEHOLDER")}
              required
            />
          </Col>

          <Col md={12} className="mb-2">
            <Form.Label>{t("CONTRACTS_CUSTOM_FIELDS.INTERNAL_NAME")}</Form.Label>
            <Form.Control
              value={internalName || ""}
              style={{ width: "100%" }}
              onInput={(e) => handleChangeInternalName(e.currentTarget.value)}
              id={"internalName"}
              name={"internalName"}
              placeholder={t("CONTRACTS_CUSTOM_FIELDS.INTERNAL_NAME_PLACEHOLDER")}
              type="select"
              required
            />
          </Col>


          {existsOptionValues && (
            <div className="option-box-container mb-2">
              <h4>{t("CONTRACTS_CUSTOM_FIELDS.OPTIONS")}</h4>
              {(options || []).map((e: any, index: number) => {
                return (<div className="option-box"><Row>
                  <Col md={4}>
                    <Form.Label>{t("CONTRACTS_CUSTOM_FIELDS.OPTION_TITLE")}:</Form.Label>
                    <Form.Control
                      value={e?.title}
                      style={{ width: "100%" }}
                      onInput={(event) => handleOptionFields(index, event)}
                      name={"title"}
                      type="select"
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Label>{t("CONTRACTS_CUSTOM_FIELDS.OPTION_VALUE")}:</Form.Label>
                    <Form.Control
                      value={e?.value || ""}
                      style={{ width: "100%" }}
                      onInput={(event) => handleOptionFields(index, event)}
                      name={"value"}
                      type="select"
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Label>{t("CONTRACTS_CUSTOM_FIELDS.OPTION_INTERNAL_NAME")}</Form.Label>
                    <Form.Control
                      value={e?.key || ""}
                      style={{ width: "100%" }}
                      onInput={(event) => handleOptionFields(index, event)}
                      name={"key"}
                      type="select"
                      required
                    />
                  </Col>
                </Row>
                  <div className="d-flex align-items-center justify-content-end gap-1 pt-2">
                    {options?.length > 1 && (
                      <Button onClick={event => handleRemoveOption(index)} size="sm" variant="link" className="d-flex align-items-center justify-content-between gap-1 text-primary border border-primary">
                        <FeatherIcon size={18} icon="x-circle" /> {t("CONTRACTS_CUSTOM_FIELDS.REMOVE_OPTION")}
                      </Button>
                    )}

                  </div>
                </div>)
              })}
              <div className="add-option">
                <Button onClick={event => setOptions([...options, initialOptions])} variant="primary">{t("CONTRACTS_CUSTOM_FIELDS.ADD_OPTION")}</Button>
              </div>
            </div>)}

          <Col md={12} className="mb-2">
            <Form.Label>{t("CONTRACTS_CUSTOM_FIELDS.FIELD_REQUIREMENT")}</Form.Label>
            <Form.Check
              onChange={(e) => handleSwitch(e.target.checked)}
              type="switch"
              id="custom-switch"
              className="mb-2"
              defaultChecked={required}
              label={t("CONTRACTS_CUSTOM_FIELDS.REQUIRED")}
            />
          </Col>





          <Col md={12} className="mb-2">
            <Button variant="primary" type="submit" disabled={loading}>
              {t("CONTRACTS_CUSTOM_FIELDS.CREATE")}
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(CreateCustomFields);
