import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Row, Col, Form, Container, Card } from "react-bootstrap";
import Select, { components } from "react-select";
import _, { values } from "lodash";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect, useDispatch } from "react-redux";
import "./index.scss"
import {
    getContractTypes,
    createContractCompany,
    getCompanyStatuses,
    getCompanyPipelines,
    editContractDetails,
} from "@redux/contract/index.actions";
import {
    toggleGenericAddContractModal, toggleGenericAddPipelineModal, toggleGenericAddContractTypeModal
} from "@redux/components/index.actions";

import FormInput from "@components/FormInput";
import CustomFieldArea, { TCustomComponent } from "@components/CustomFieldArea";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Loader from '@components/Loader';
import moment from "moment";
import FeatherIcons from "feather-icons-react";

import './EditContract.scss';

const mapStateToProps = (state: any) => ({
    users: state.users,
    contracts: state.contracts,
    companies: state.companies,
});

const mapActionsToProps = {
    createContractCompany,
    getContractTypes,
    getCompanyStatuses,
    getCompanyPipelines,
    editContractDetails,
    toggleGenericAddContractModal,
    toggleGenericAddPipelineModal, toggleGenericAddContractTypeModal
};

const AddNewPipeline = (props: any) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(toggleGenericAddPipelineModal({}));
    }
    return (
        <components.MenuList {...props}>
            <Button
                onClick={handleClick}
                className="react-select-inner-button btn btn-sm btn-primary outline-0 border-0 pointer"
            >
                <i className="icon">
                    <span>
                        <FeatherIcons icon="plus-square"></FeatherIcons>
                    </span>

                </i> Add New
            </Button>
            {props.children}
        </components.MenuList>
    );
};

const AddNewContractType = (props: any) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(toggleGenericAddContractTypeModal({}));
    }
    return (
        <components.MenuList {...props}>
            <Button
                onClick={handleClick}
                className="react-select-inner-button btn btn-sm btn-primary outline-0 border-0 pointer"
            >
                <i className="icon">
                    <span>
                        <FeatherIcons icon="plus-square"></FeatherIcons>
                    </span>

                </i> Add New
            </Button>
            {props.children}
        </components.MenuList>
    );
};

const StatusAdd = (props: any) => {
    const { t } = useTranslation();

    const editedContract = props?.contracts?.contractOverview



    const [data, setData] = useState<{
        _id: string
        title: string
        description?: string
        startDate?: string
        endDate?: string
        renewalDate?: string
        effectiveDate?: string
        status: string
        pipeline?: string
        type: string
        private: boolean
    }>({
        _id: editedContract?._id,
        title: editedContract?.title,
        description: editedContract?.description,
        startDate: editedContract?.startDate,
        endDate: editedContract?.endDate,
        renewalDate: editedContract?.renewalDate,
        effectiveDate: editedContract?.effectiveDate,
        status: editedContract?.status?._id,
        pipeline: editedContract?.pipeline?._id,
        type: editedContract?.type?._id,
        private: editedContract?.private,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsData, setCustomFieldsData] = useState<{}>({});

    const [newPipeline, setNewPipeline] = useState(null);
    const [newStatus, setNewStatus] = useState(editedContract?.status?._id);
    const [error, setError] = useState("");

    const [selectedPipeline, setSelectedPipeline] = useState<any>({
        _id: editedContract?.pipeline?._id,
        statuses: editedContract?.pipeline?.statuses,
    });


    const handleInput = (target: any) => {

        if (target?.name === 'title') {
            console.log('title', target?.value)
        }
        setData({ ...data, ...{ [target?.name]: target?.value } })
    }

    const handleCustomFields = (event: any) => {

        setCustomFieldsData({
            ...customFieldsData, ...{
                [event?.target?.name]: {
                    field: event?.target?.name,
                    value: event?.target?.value,
                    relatedOption: null,
                }
            }
        })
    }

    const handleOnChange = (event: any, e: any) => {

        //e?.field?._id

        setCustomFieldsData({
            ...customFieldsData, ...{
                [e?.field?._id]: {
                    field: e?.field?._id,
                    value: event?.opt?.value,
                    relatedOption: event?.value,
                }
            }
        })

    }

    const handleSwitch = (switchData: {
        name: string,
        value: boolean
    }) => {
        setData({ ...data, ...{ [switchData?.name]: switchData?.value } })
    }



    const handleContractType = (target: any) => {
        setData({ ...data, ...{ [target?.name]: target?.value } })

        const contractType = _.filter(props?.contracts?.types?.ContractsTypes, { _id: target?.value });

        setCustomFieldsData({});
        setCustomFields([])

        const cf = contractType[0]?.customFields
        const newData: any = {};
        contractType[0]?.customFields?.map((e: any) => {
            newData[e?.field?._id] = {
                field: e?.field?._id,
                value: ""
            }
        })
        setCustomFieldsData(newData);
        setCustomFields(cf?.length ? cf : []);
    }

    const handlePipeline = (target: any) => {
        if (target.length) {
            const obj: any = {

            };

            target.map((e: any) => {
                obj[e?.name] = e?.value;
            })

            setData({
                ...data,
                ...obj
            })
        } else {
            setData({
                ...data,
                [target?.name]: target?.value
            })
        }
        if (target?.name === "status") {
            //alert(target.value)
            console.log("FULLCOMMAND", {
                ...data,
                [target?.name]: target?.value
            })
            console.log("COMMAND:", { [target?.name]: target?.value })
            console.log("UPDATED STATUS: ", target?.value)
            setTimeout(() => {
                console.log("UPDATED DATA STATUS: ", data?.status)

            }, 1500)
        }
    }


    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        const requestData: {
            _id: string
            title: string
            description?: string
            startDate?: string
            endDate?: string
            renewalDate?: string
            effectiveDate?: string
            status?: string
            pipeline?: string
            type: string
            company: string
            customFields?: any[]
            private: boolean
        } = {
            _id: data?._id,
            title: data?.title,
            description: data?.description,
            startDate: data?.startDate,
            endDate: data?.endDate,
            renewalDate: data?.renewalDate,
            effectiveDate: data?.effectiveDate,
            status: data?.status,
            pipeline: data?.pipeline,
            type: data?.type,
            company: props?.companies?.currentCompany,
            customFields: _.values(customFieldsData),
            private: data?.private
        }

        console.error("REQUEST DATA:", requestData)

        if (!requestData?._id) {
            toast.warning(t("CONTRACTS.EDIT_CONTRACT.SOMETHING_IS_WRONG"))
            setLoading(false);
            return;
        }

        if (!requestData?.title) {
            toast.warning(t("CONTRACTS.EDIT_CONTRACT.NO_TITLE"))
            setLoading(false);
            return;
        }
        if (!requestData?.status) {
            toast.warning(t("CONTRACTS.EDIT_CONTRACT.NO_STATUS"))
            setLoading(false);
            return;
        }
        if (!requestData?.type) {
            toast.warning(t("CONTRACTS.EDIT_CONTRACT.NO_TYPE"))
            setLoading(false);
            return;
        }
        if (!requestData?.company) {
            toast.warning(t("CONTRACTS.EDIT_CONTRACT.NO_COMPANY"))
            setLoading(false);
            return;
        }

        await props.editContractDetails(requestData).then((res: any) => {
            if (res === false) {
                setLoading(false);
            } else {
                props?.close()
                setLoading(false);
                setCustomFieldsData([]);
                setCustomFields([])
            }

        }).catch(() => {
            setLoading(false);
        })


    }
    const typeDefaultValue = (): {
        label: string
        value: string
    } => {
        //let data = _.filter(props?.contracts?.types?.ContractsTypes, { _id: editedContract?.type })[0]
        return {
            label: editedContract?.type?.title,
            value: editedContract?.type?._id
        }
    }
    const statusDefaultValue = (): {
        label: string
        value: string
    } => {
        let data = _.filter(props?.contracts?.statuses, { _id: editedContract?.status })[0]
        return {
            label: data?.title,
            value: data?._id
        }
    }
    const pipelineDefaultValue = (): {
        label: string
        value: string
    } => {
        let data = _.filter(props?.contracts?.pipelines, { _id: editedContract?.pipeline })[0]
        setSelectedPipeline(data);
        return {
            label: data?.title,
            value: data?._id
        }
    }

    useEffect(() => {
        const contractType = _.filter(props?.contracts?.types?.ContractsTypes, { _id: editedContract?.type?._id });
        const cf = contractType[0]?.customFields
        setCustomFields(cf?.length ? cf : []);
        const currentCustomFieldsData: any = {}
        editedContract?.customFields?.map((e: any) => {
            currentCustomFieldsData[e?.field?._id] = {
                field: e?.field?._id,
                value: e?.value,
                relatedOption: e?.relatedOption
            }
        })

        setCustomFieldsData(currentCustomFieldsData)
        setCustomFields(cf?.length ? cf : []);


    }, [props?.contracts?.types?.ContractsTypes])

    return (
        <form onSubmit={event => handleSubmit(event)} className="p-2">
            <Container>
                <Row className="mb-2">
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>
                                {t("CONTRACTS.EDIT_CONTRACT.TITLE_LABEL")}
                            </Form.Label>
                            <FormInput
                                defaultValue={editedContract?.title}
                                disabled={loading}
                                className="form-control form-control-xs"
                                name="title"
                                type="text"
                                placeholder={t("CONTRACTS.EDIT_CONTRACT.TITLE_PLACEHOLDER")}
                                onBlur={(event) => handleInput(event?.target)}
                            />
                        </Form.Group>
                    </Col>

                </Row>
                <Row className="mb-2">
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>
                                {t("CONTRACTS.EDIT_CONTRACT.DESCRIPTION_LABEL")}
                            </Form.Label>
                            <FormInput
                                defaultValue={editedContract?.description}
                                disabled={loading}
                                className="form-control form-control-xs"
                                name="description"
                                placeholder={t("CONTRACTS.EDIT_CONTRACT.DESCRIPTION_PLACEHOLDER")}
                                type="textarea"
                                onBlur={(event) => handleInput(event?.target)}
                            />
                        </Form.Group>

                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>
                                {t("CONTRACTS.EDIT_CONTRACT.START_DATE_LABEL")}
                            </Form.Label>
                            <FormInput
                                defaultValue={editedContract?.startDate ? moment(editedContract?.startDate).format('YYYY-MM-DD') : ''}
                                disabled={loading}
                                className="form-control form-control-xs"
                                name="startDate"
                                type="date"
                                onInput={(event) => handleInput(event?.target)}
                            />
                        </Form.Group>

                    </Col>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>
                                {t("CONTRACTS.EDIT_CONTRACT.END_DATE_LABEL")}
                            </Form.Label>
                            <FormInput
                                defaultValue={editedContract?.endDate ? moment(editedContract?.endDate).format('YYYY-MM-DD') : ''}
                                disabled={loading}
                                className="form-control form-control-xs"
                                name="endDate"
                                type="date"
                                onInput={(event) => handleInput(event?.target)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>
                                {t("CONTRACTS.EDIT_CONTRACT.EFFECTIVE_DATE_LABEL")}
                            </Form.Label>
                            <FormInput
                                defaultValue={editedContract?.effectiveDate ? moment(editedContract?.effectiveDate).format('YYYY-MM-DD') : ''}
                                disabled={loading}
                                className="form-control form-control-xs"
                                name="effectiveDate"
                                type="date"
                                onInput={(event) => handleInput(event?.target)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>
                                {t("CONTRACTS.EDIT_CONTRACT.RENEWAL_DATE_LABEL")}
                            </Form.Label>
                            <FormInput
                                defaultValue={editedContract?.renewalDate ? moment(editedContract?.renewalDate).format('YYYY-MM-DD') : ''}
                                disabled={loading}
                                className="form-control form-control-xs"
                                name="renewalDate"
                                type="date"
                                onInput={(event) => handleInput(event?.target)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md={4}>

                        <Form.Group>
                            <Form.Label>
                                {t("CONTRACTS.EDIT_CONTRACT.PIPELINE_LABEL")}
                            </Form.Label>
                            <Select
                                defaultValue={{
                                    label: editedContract?.pipeline?.title,
                                    value: editedContract?.pipeline?._id,
                                    data: editedContract?.pipeline
                                }}
                                isDisabled={loading}
                                isClearable={true}
                                isMulti={false}
                                placeholder={t("CONTRACTS.EDIT_CONTRACT.PIPELINE_PLACEHOLDER")}
                                options={props?.contracts?.pipelines.map((pipeline: any) => {
                                    return {
                                        value: pipeline?._id,
                                        label: pipeline?.title,
                                        data: pipeline,
                                    };
                                })}
                                onChange={(e: any) => {
                                    if (!e || e == null) {
                                        /* setSelectedPipeline({
                                            statuses: [],
                                        }) */
                                        setNewStatus(null);
                                    }
                                    setSelectedPipeline(e?.data);
                                    setNewPipeline(e?.value);
                                    if (e?.value === props?.contracts?.contractOverview?.pipeline?._id) {
                                        setNewStatus(props?.contracts?.contractOverview?.status?._id)
                                        handlePipeline([{ name: 'pipeline', value: e?.value }, {
                                            name: 'status',
                                            value: props?.contracts?.contractOverview?.status?._id
                                        }])
                                    } else {
                                        setNewStatus(e?.data?.statuses[0]?._id);
                                        handlePipeline([{ name: 'pipeline', value: e?.value }, { name: 'status', value: e?.data?.statuses[0]?._id }])
                                    }
                                    //handlePipeline({ name: 'pipeline', value: e?.value })
                                }}
                                className="react-select react-select-container"
                                classNamePrefix="react-select"
                                components={{ MenuList: AddNewPipeline }}
                            ></Select>

                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>
                                {t("CONTRACTS.EDIT_CONTRACT.STATUS_LABEL")}
                            </Form.Label>
                            <Select
                                isClearable={true}
                                isDisabled={loading || selectedPipeline?.statuses.length <= 0}
                                isMulti={false}
                                placeholder={t("CONTRACTS.EDIT_CONTRACT.STATUS_PLACEHOLDER")}
                                options={
                                    selectedPipeline?.statuses
                                        ? selectedPipeline?.statuses.map((status: any) => {
                                            return {
                                                value: status?._id,
                                                label: status?.title,
                                            };
                                        })
                                        : []
                                }
                                defaultValue={{
                                    value: newStatus,
                                    label: selectedPipeline?.statuses?.find((status: any) => status?._id === newStatus)?.title
                                }}
                                value={{
                                    value: newStatus,
                                    label: selectedPipeline?.statuses?.find((status: any) => status?._id === newStatus)?.title
                                }}
                                onChange={(e: any) => {
                                    setNewStatus(e?.value);
                                    handlePipeline({ name: 'status', value: e?.value })
                                }}
                                className="react-select react-select-container"
                                classNamePrefix="react-select"
                            ></Select>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>
                                {t("CONTRACTS.EDIT_CONTRACT.TYPE_LABEL")}
                            </Form.Label>
                            <Select
                                value={{
                                    label: editedContract?.type?.title,
                                    value: editedContract?.type?._id
                                }}
                                isDisabled={loading}
                                isClearable={true}
                                isMulti={false}
                                placeholder={t("CONTRACTS.EDIT_CONTRACT.TYPE_PLACEHOLDER")}
                                options={
                                    props?.contracts?.types?.ContractsTypes
                                        ? props?.contracts?.types?.ContractsTypes.map((status: any) => {
                                            return {
                                                value: status?._id,
                                                label: status?.title,
                                            };
                                        })
                                        : []
                                }
                                onChange={(e: any) => {
                                    setCustomFieldsData({})
                                    setCustomFields([])
                                    handleContractType({ name: 'type', value: e?.value })
                                }}
                                className="react-select react-select-container"
                                classNamePrefix="react-select"
                                components={{ MenuList: AddNewContractType }}
                            ></Select>

                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mt-2">
                        <Form.Group>
                            <Form.Label>{t("CONTRACTS.EDIT_CONTRACT.MAKE_PRIVATE")}</Form.Label>
                            <Form.Check
                                name={"private"}
                                type="switch"
                                checked={data?.private}
                                onChange={(event: any) => {
                                    handleSwitch({
                                        name: "private",
                                        value: event.target?.checked,
                                    })

                                    /* if (event.target?.value === "on") {
                                        handleSwitch({
                                            name: "private",
                                            value: true
                                        })
                                    } else {
                                        handleSwitch({
                                            name: "private",
                                            value: false
                                        })
                                    } */
                                }}
                                className="edit-contract-switch"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {customFields?.length > 0 && (<CustomFieldArea>
                    {(customFields || []).map((e: {
                        field: {
                            title: string;
                            key: string;
                            CustomComponent: TCustomComponent
                            isOptional: boolean
                            _id: string
                            existOptionValues: boolean
                            OptionValues: any
                        },
                        _id: string;
                        value: any;
                    }, index: number) => {


                        if (e?.field?.existOptionValues === true) {
                            let detailField = _.filter(editedContract?.customFields, { _id: e?.field?._id })[0] || ''
                            let detailFieldValue = _.filter(e?.field?.OptionValues, { _id: detailField?.relatedOption })[0] || ''

                            return React.createElement(CustomFieldArea[e?.field?.CustomComponent], {
                                key: 'cf-' + e?._id,
                                size: 6,
                                name: e?.field?._id,
                                label: e?.field?.title,
                                value: {
                                    value: detailFieldValue?.value,
                                    label: detailFieldValue?.title
                                },
                                onChange: (event: any) => handleOnChange(event, e),
                                require: e?.field?.isOptional,
                                data: e
                            })
                        } else {
                            return React.createElement(CustomFieldArea[e?.field?.CustomComponent], {
                                key: 'cf-' + e?._id,
                                size: 6,
                                value: _.filter(editedContract?.customFields, { _id: e?.field?._id })[0]?.value || '',
                                name: e?.field?._id,
                                label: e?.field?.title,
                                onInput: (event) => handleCustomFields(event),
                                require: e?.field?.isOptional,
                                data: e
                            })
                        }

                    })}

                </CustomFieldArea>)}
                <Row className="mb-2">
                    <Col md={12}>
                        <div className="text-end mt-3">
                            <Button
                                disabled={loading}
                                variant="secondary"
                                className="me-1"
                                onClick={props?.close}
                            >
                                {t("CONTRACTS.EDIT_CONTRACT.CANCEL")}
                            </Button>
                            <Button disabled={loading} name="btn" value={1} variant="success" type="submit">
                                {loading && <Loader />} {
                                    t("CONTRACTS.EDIT_CONTRACT.SAVE")
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </form>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(StatusAdd);
